import { Component, createRef } from "react";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from 'primereact/inputnumber';
import { Navigate } from "react-router-dom";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class Operadora extends Component {
    mensagem = createRef();
    toast = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaOperadora: new Pessoa(),
        vModalNovo: false,
        salvarCarregando: false,
        tabelaCarregando: false,
        listaOperadora: [],
        liberarAcesso: {
            detalheOperadora: false,
        },
        inputError: {
            nomeOperadora: false,
            id: false,
            estadoOperadora: false,
        },
        filtro: {
            nome: '',
            idOperadora: null,
            estadoOperadora: '',
            dataCadastro_inicial: '',
            dataCadastro_final: '',
        }

    }

    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.listar()
        this.setState({
            pessoaUsuario: pessoaUsuario,
        })
    }

    async salvar() {
        this.setState({ salvarCarregando: true })
        this.mensagem.current.clear();
        let pessoaOperadora = this.state.pessoaOperadora;
        pessoaOperadora.seqUsuario = this.constante.seqUsuario;
        pessoaOperadora.seqPessoaPerfil = this.constante.seqPerfilOperadora;
        pessoaOperadora.situacao = "ATIVO";
        let retorno = await this.pessoaService.salvar(pessoaOperadora);
        pessoaOperadora = retorno.objeto;
        console.log("operadora salva", pessoaOperadora);
        this.listar()
        this.resetar();

        this.mensagem.current.show([
            {
                severity: "success",
                summary: "Operadora cadastrada com sucesso.",
                sticky: true,
            },
        ]);
    }

    resetar() {
        let inputError = this.state.inputError;
        let listaKey = Object.keys(inputError);
        listaKey.forEach(key => inputError[key] = false);
        this.setState({
            vModalNovo: false,
            inputError: inputError,
            salvarCarregando: false,
            pessoaOperadora: new Pessoa(),
        })
    }

    validarInputs() {
        let inputError = this.state.inputError;
        console.log(inputError);
        let listaKey = Object.keys(inputError);
        let inputOk = true;
        listaKey.forEach(key => inputError[key] = false);

        if (!this.state.pessoaOperadora.nome) {
            inputError.nomeOperadora = true
        }

        if (!this.state.pessoaOperadora.tagn3) {
            inputError.id = true
        }

        listaKey.forEach(key => {
            if (inputError[key]) {
                inputOk = false
            }
        })

        this.setState({ inputError: inputError })
        return inputOk
    }


    async listar(pPessoaOperadora) {
        this.setState({ tabelaCarregando: true });
        let pessoaOperadora = this.state.pessoaOperadora;
        if (pPessoaOperadora) {
            pessoaOperadora = pPessoaOperadora;
        }
        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPerfilOperadora;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);


        if (this.state.filtro.nome) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.nome";
            pesquisa.conteudo = this.state.filtro.nome;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        if (this.state.filtro.idOperadora) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.tagn3";
            pesquisa.conteudo = this.state.filtro.idOperadora;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "NUMERO";
            listaPesquisa.push(pesquisa);
        }

        if (this.state.filtro.dataCadastro_inicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.data_cadastro";
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastro_inicial);
            pesquisa.operacao = "AND";
            pesquisa.tipo = "DATA_INICIAL";
            listaPesquisa.push(pesquisa);
        }

        if (this.state.filtro.dataCadastro_final) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.data_cadastro";
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastro_final);
            pesquisa.operacao = "AND";
            pesquisa.tipo = "DATA_FINAL";
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaOperadora = retorno.objeto;

        this.setState({
            listaOperadora: listaOperadora,
            tabelaCarregando: false,
        })
        return listaOperadora;
    }

    selecionar(pPessoaOperadora) {
        let pessoaOperadora = pPessoaOperadora
        console.log('operadora selecionado', pessoaOperadora);
        this.setState({ pessoaOperadora: pessoaOperadora })
        this.navegar('detalheOperadora')
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.detalheOperadora && <Navigate to={"/sistema/admin/detalhe_operadora?id=" + (this.state.pessoaOperadora._seqPessoa)} />}

            <Dialog
                header="Cadastro de operadoras"
                visible={this.state.vModalNovo}
                style={{ width: 900 }}
                onHide={() => this.resetar()}
            >
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        let inputsOkay = this.validarInputs();
                        if (!inputsOkay) {
                            return;
                        } else {
                            this.salvar();
                        }
                    }}
                    style={{
                        padding: 5,
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                    }}
                >
                    <div
                        style={{
                            marginTop: 5,
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gap: 20,
                        }}
                    >
                        <div>
                            <label>Nome operadora</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    pessoaOperadora: {
                                        ...this.state.pessoaOperadora, nome: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaOperadora.nome || ''}
                                style={{
                                    width: "100%",
                                    height: 40,
                                    borderColor: this.state.inputError.nomeOperadora && "var(--red-600)",
                                }}
                            />
                            {this.state.inputError.nomeOperadora && (
                                <small style={{ color: "var(--red-600)" }}>
                                    Nome inválido
                                </small>
                            )}
                        </div>


                        <div>
                            <label>ID da operadora(OGT)</label><br />
                            <InputNumber
                                onChange={pTexto => this.setState({
                                    pessoaOperadora: {
                                        ...this.state.pessoaOperadora, tagn3: pTexto.value
                                    }
                                })}
                                useGrouping={false}
                                value={this.state.pessoaOperadora.tagn3 || ''}
                                inputStyle={{
                                    width: "100%",
                                    height: 40,
                                    borderColor: this.state.inputError.id && "var(--red-600)",
                                }}
                            /><br />
                            {this.state.inputError.id && (
                                <small style={{ color: "var(--red-600)" }}>
                                    Id inválido
                                </small>
                            )}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            loading={this.state.salvarCarregando}
                            label="Salvar"
                            className="p-button-raised"
                            type="submit"
                        />
                    </div>
                </form>

            </Dialog>


            <HeaderAdm
                telaBloqueada={true}
            />
            <main style={{ width: "98%", margin: "20px auto" }}>
                <Messages ref={this.mensagem} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid lightgray",
                        padding: 20,
                        borderRadius: 3,
                    }}
                >
                    <div>
                        <h1 style={{ fontSize: 22 }}>Operadoras</h1>
                        <h2 style={{ fontSize: 20, color: 'gray' }}>Operadoras</h2>
                    </div>
                    <Button
                        label="Novo"
                        className="p-button-raised"
                        onClick={() => this.setState({ vModalNovo: true })} />
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>

                        {/* filtro */}

                        <div>
                            <label>Nome</label><br />
                            <InputText
                                onChange={pTexto => this.setState({ filtro: { ...this.state.filtro, nome: pTexto.target.value } })}
                                value={this.state.filtro.nome || ''}
                                placeholder="Nome"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>


                        <div>
                            <label>Data Cadastro Inicial</label><br />
                            <Calendar
                                onChange={(pTexto) =>
                                    this.setState({
                                        filtro: {
                                            ...this.state.filtro,
                                            dataCadastro_inicial: pTexto.target.value,
                                        },
                                    })
                                }
                                showButtonBar
                                style={{ width: "100%", height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                            />
                        </div>

                        <div>
                            <label>Data Cadastro Final</label><br />
                            <Calendar
                                onChange={(pTexto) =>
                                    this.setState({
                                        filtro: {
                                            ...this.state.filtro,
                                            dataCadastro_final: pTexto.target.value,
                                        },
                                    })
                                }
                                showButtonBar
                                style={{ width: "100%", height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', width: "100%", marginTop: 20, justifyContent: "flex-end" }}>
                        <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        gridTemplateColumns: "1fr",
                        marginTop: 20,
                        gap: 10,
                    }}
                >
                    <DataTable
                        selectionMode="single"
                        showGridlines
                        removableSort
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        style={{ width: "100%" }}
                        value={this.state.listaOperadora}
                        onSelectionChange={e => this.selecionar(e.value)}
                        loading={this.state.tabelaCarregando}
                    >
                        <Column header='Nome' field='nome' sortable />
                        <Column header='Data cadastro' sortable body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />
                    </DataTable>
                </div>

            </main>
        </div>
    }
}


