import Constante from "../Util/Constante";

export class OgtService {
  constructor(pUrlBase) {
    this.urlBase = pUrlBase;
  }

  async validarAcessoOgt(pCpf, pSenha) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");

    let objeto = {
      cpf: pCpf,
      senha: pSenha,
    };

    console.log("Cabeçalho", cabecalho);
    let res = await fetch(`${this.urlBase}/autenticar`, {
      method: 'POST',
      headers: cabecalho,
      body: JSON.stringify(objeto),
      redirect: 'follow'
    });

    res = await res.json();
    return res;
  };

  async enviarLote(pPedido, pToken) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");
    cabecalho.append("token", `${pToken}`);
    let res = await fetch(`${this.urlBase}/enviar_lote`, {
      method: 'POST',
      headers: cabecalho,
      redirect: 'follow',
      body: JSON.stringify(pPedido)
    });
    res = await res.json();
    return res;
  }

  async obterResultado(pIdPedido, pNrSolicitacao, pToken) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");
    cabecalho.append("token", `${pToken}`);

    let res = await fetch(`${this.urlBase}/obter_resultado?id_pedido=${pIdPedido}&nr_solicitacao=${pNrSolicitacao}`, {
      method: 'GET',
      headers: cabecalho,
      redirect: 'follow',
    });
    res = await res.json();
    return res;
  }

}