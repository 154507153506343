import { Component, createRef } from "react";
import { Toast } from "primereact/toast";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";
import { Button } from "primereact/button";
import { Navigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { PedidoItemOgt } from "../../Service/PedidoItemOgt";
import { PedidoOgt } from "../../Service/PedidoOgt";
import { OgtService } from "../../Service/OgtService";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import PessoaAtendimento from "lirida-back-service/Servicos/PessoaAtendimento/PessoaAtendimento";

export default class DetalheCartão extends Component {
    toast = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    ogtService = new OgtService(this.constante.url_base_ogt);


    state = {
        pessoaFuncionario: new Pessoa(),
        pessoaEmpresa: new Pessoa(),
        pessoaOperadoraRelacionamento: new PessoaRelacionamento(),
        pessoaUsuario: new Pessoa(),

        parametrosUrl: '',
        salvarCarregando: false,
        vModalDeletar: false,
        deletarCarregando: false,
        buscarCarregando: false,
        diaDaSemana: null,

        listaOperadora: [],
        liberarAcesso: {
            cartao: false,
        },

        inputsError: {
            nome: false,
            cpf: false,
            matricula: false,
            nCartao: false,
            diasTrabalhado: false,
            valorDiario: false
        },
    }


    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let retornoParametrosUrl = this.util.buscarParametros();
        let pessoaEmpresa = await this.util.buscarLocalStorage("PESSOA_EMPRESA");
        console.log('PESSOA EMPRESA => ', pessoaEmpresa);

        const retornoPessoaFuncionario = await this.pessoaService.buscarPorSeqPessoa(retornoParametrosUrl.id)
        let pessoaFuncionario = retornoPessoaFuncionario.objeto;
        console.log("PESSOA FUNCIONARIO =>", pessoaFuncionario);

        let listaOperadora = await this.listarRelacionamentoOperadora(pessoaEmpresa);
        console.log('LISTA DE OPERADORA =>', listaOperadora);

        let pessoaOperadoraRelacionamento = new PessoaRelacionamento();
        for (let i = 0; i < listaOperadora?.length; i++) {
            if (listaOperadora[i].tagn0 === pessoaFuncionario.tagn3) {
                pessoaOperadoraRelacionamento = listaOperadora[i];
                console.log("OPERADORA SELECIONADA", pessoaOperadoraRelacionamento);
            }
        }

        this.setState({
            listaOperadora: listaOperadora,
            pessoaOperadoraRelacionamento: pessoaOperadoraRelacionamento,
            pessoaUsuario: pessoaUsuario,
            pessoaFuncionario: pessoaFuncionario,
            pessoaEmpresa: pessoaEmpresa,
        });
    }

    async salvar() {
        this.setState({ salvarCarregando: true })
        let retornoValidacao = await this.validarInputs()
        if (!retornoValidacao) {
            return
        }
        let pessoaFuncionario = this.state.pessoaFuncionario
        pessoaFuncionario.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;
        pessoaFuncionario.tags2 = this.state.pessoaOperadoraRelacionamento._pessoaRelacionadaNome;
        pessoaFuncionario.tagn3 = this.state.pessoaOperadoraRelacionamento.tagn0;
        pessoaFuncionario.tags5 = this.state.pessoaOperadoraRelacionamento.tags0;
        pessoaFuncionario.tags6 = this.state.pessoaOperadoraRelacionamento.tags1;

        console.log('SALVO', pessoaFuncionario)
        let retorno = await this.pessoaService.salvar(pessoaFuncionario)
        pessoaFuncionario = retorno.objeto

        this.toast.current.show([
            { severity: 'success', summary: ' Alteração armazenadas com sucesso!' },
        ]);
        this.setState({ pessoaFuncionario: pessoaFuncionario, salvarCarregando: false })
    }

    validarInputs() {
        let inputsError = this.state.inputsError;
        let listaKeys = Object.keys(inputsError);
        let inputsOk = true;

        listaKeys.forEach(key => {
            inputsError[key] = false
        })

        if (!this.state.pessoaFuncionario.nome) {
            inputsError.nome = true;
        }

        if (this.state.pessoaFuncionario.documento && this.state.pessoaFuncionario.documento.length !== 14) {
            inputsError.cpf = true;
        }

        if (!this.state.pessoaFuncionario.documento) {
            inputsError.cpf = true;
        }

        if (!this.state.pessoaFuncionario.tags0) {
            inputsError.matricula = true;
        }

        if (!this.state.pessoaFuncionario.tags1) {
            inputsError.nCartao = true;
        }


        if (!this.state.pessoaFuncionario.tagn0) {
            inputsError.diasTrabalhado = true;
        }


        if (!this.state.pessoaFuncionario.tagn1) {
            inputsError.valorDiario = true;
        }

        listaKeys.forEach(key => {
            if (inputsError[key]) {
                inputsOk = false
            }
        })
        this.setState({
            inputsError: inputsError
        })
        return inputsOk
    }

    async listarRelacionamentoOperadora(pEmpresa) {
        let pessoaEmpresa = this.state.pessoaEmpresa;
        if (pEmpresa) {
            pessoaEmpresa = pEmpresa;
        }
        console.log("pessoa empresa", pessoaEmpresa);

        let retorno = await this.pessoaService.listarRelacionamento(pessoaEmpresa);
        console.log("RETORNO DO LISTAR", retorno);
        let listaOperadora = retorno?.objeto;
        this.setState({ listaOperadora: listaOperadora });
        return listaOperadora;
    }

    async buscarSaldo() {
        this.setState({ buscarCarregando: true })
        let pessoaFuncionario = this.state.pessoaFuncionario;
        let pessoaEmpresa = this.state.pessoaEmpresa;
        let retorno = await this.pessoaService.buscarPorSeqPessoa(pessoaEmpresa.seqPessoaRelacionada);
        pessoaEmpresa = retorno.objeto;
        let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
        let token = retornoOgt?.data?.accessToken;
        console.log("TOKEN", token);

        let listaItem = [];
        let pedidoItemOgt = new PedidoItemOgt();
        pedidoItemOgt.nomeFuncionario = pessoaFuncionario.nome;
        pedidoItemOgt.cpf = pessoaFuncionario.documento;
        pedidoItemOgt.numeroCartao = pessoaFuncionario.tags1;
        pedidoItemOgt.idOperadora = pessoaFuncionario.tagn3;
        pedidoItemOgt.loginOperadora = pessoaFuncionario.tags5;
        pedidoItemOgt.senhaOperadora = pessoaFuncionario.tags6;
        pedidoItemOgt.cdempresa = this.constante.cdEmpresa;
        console.log('Pedido Item', pedidoItemOgt);
        listaItem.push(pedidoItemOgt);

        let retornoAdm = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaSuperAdmin);
        let pessoaSuperAdmin = retornoAdm.objeto;
        let pedidoOgt = new PedidoOgt();
        pedidoOgt.idArquivo = this.constante.idArquivo;
        pedidoOgt.pedidoItens = listaItem;
        pedidoOgt.idPedido = pessoaSuperAdmin.tagn0 + 1;
        pedidoOgt.nrSolicitacao = 0;
        console.log('Pedido', pedidoOgt);

        pessoaSuperAdmin.tagn0 = pedidoOgt.idPedido;
        retornoAdm = await this.pessoaService.salvar(pessoaSuperAdmin);
        console.log("PESSOA ADM", retornoAdm.objeto);
        retornoOgt = await this.ogtService.enviarLote(pedidoOgt, token);
        console.log("lote", retornoOgt);

        if (retornoOgt?.success === false) {
            pessoaFuncionario.tags9 = '1';
            this.toast.current.show([
                { severity: 'error', summary: retornoOgt.errors[0] },
            ]);
            this.setState({ buscarCarregando: false })
            return
        } else {
            pessoaFuncionario.tags9 = '2';
            pessoaFuncionario.tagn4 = pedidoOgt.idPedido;
            retorno = await this.pessoaService.salvar(pessoaFuncionario);
            this.toast.current.show([
                { severity: 'success', summary: ' Saldo buscado com sucesso!' },
            ]);

        }
        let date = new Date();
        let pessoaAtendimento = new PessoaAtendimento();
        pessoaAtendimento.seqPessoa = this.state.pessoaUsuario._seqPessoa;
        pessoaAtendimento.seqTipoAtendimento = this.constante.seqTipoAtendimentoRequisicaoOGT;
        pessoaAtendimento.descricao = `${listaItem?.length} \n ${date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`;
        let retornoAtendimento = await this.pessoaService.salvarAtendimento(pessoaAtendimento);
        console.log('ATENDIMENTO', retornoAtendimento);
        console.log('SALVO', retorno.objeto);
        this.setState({ buscarCarregando: false, pessoaFuncionario: pessoaFuncionario });
    }

    async AtualizarSaldo() {
        this.setState({ buscarCarregando: true });
        let pessoaFuncionario = this.state.pessoaFuncionario;
        console.log("Cartão selecionado", pessoaFuncionario)
        let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
        let token = retornoOgt?.data?.accessToken;
        console.log("TOKEN", token);
        retornoOgt = await this.ogtService.obterResultado(pessoaFuncionario.tagn4, 0, token);
        console.log('retorno ogt', retornoOgt);

        if (!retornoOgt.data) {
            this.toast.current.show([
                { severity: 'warn', summary: "Informativo", detail: retornoOgt.statusPedido },
            ]);
            this.setState({ buscarCarregando: false })
            return

        } else {
            pessoaFuncionario.tagn2 = retornoOgt.data[0].saldo;
            pessoaFuncionario.tagn5 = retornoOgt.data[0].saldoDesmembrado;
            pessoaFuncionario.tagn6 = retornoOgt.data[0].saldoPendenteDesmembrado;
            pessoaFuncionario.tags7 = this.util.formatarHora(retornoOgt.data[0].dataUltimaAtualizacao.split("T")[1]);
            pessoaFuncionario.tags8 = this.util.formatarHora(retornoOgt.data[0].dataProcessamento.split("T")[1]);
            pessoaFuncionario.tagd0 = this.util.converterDataRegex(retornoOgt.data[0].dataUltimaAtualizacao);
            pessoaFuncionario.tagd1 = this.util.converterDataRegex(retornoOgt.data[0].dataProcessamento);
            pessoaFuncionario.tags9 = '1';
            let retornoPessoa = await this.pessoaService.salvar(pessoaFuncionario);
            pessoaFuncionario = retornoPessoa.objeto;
            console.log('atualizado', pessoaFuncionario)
            this.toast.current.show([
                { severity: 'success', summary: ' Saldo atualizado com sucesso!' },
            ]);
            this.setState({ buscarCarregando: false, pessoaFuncionario: pessoaFuncionario });
        }
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }
    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.cartao && <Navigate to="/sistema/admin/cartao" />}

            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar funcionario?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletar();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>


            {/* TELA */}

            <HeaderAdm
                setState={(pPessoaEmpresa) => this.setState({ pessoaEmpresa: pPessoaEmpresa })}
                telaBloqueada={true}
            />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <Toast ref={this.toast} />

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.navegar("cartao")} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe Cartão</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe Cartão</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => this.salvar()} />
                    </div>
                </div>

                <form
                    onSubmit={e => {
                        e.preventDefault();
                        this.salvar();
                    }}
                    style={{ display: "flex", flexDirection: "column", gap: 10, }}>


                    {/* DADOS FUNCIONARIO */}
                    <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
                        <div style={{ display: "flex", alignItems: "center", gap: 5, }}>
                            <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-user" className="p-button-rounded p-button-info p-button-outlined" aria-label="Home" />
                            <h3>Dados Funcionários</h3>
                        </div>

                        <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                            <div>
                                <label>Nome</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, nome: pTexto.target.value }
                                    })}

                                    value={this.state.pessoaFuncionario.nome || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.nome && "var(--red-600)" }} />
                                {this.state.inputsError.nome && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Nome inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>CPF</label><br />
                                <InputText
                                    type="tel"
                                    value={this.state.pessoaFuncionario.documento || ''}
                                    onChange={e => {
                                        if (e.target.value.length > 14) return;
                                        this.setState({
                                            pessoaFuncionario: {
                                                ...this.state.pessoaFuncionario,
                                                documento: this.util.formatarCPF(e.target.value)
                                            }
                                        })
                                    }}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.cpf && "var(--red-600)" }} />
                                {this.state.inputsError.cpf && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Cpf inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>Matricula</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tags0: pTexto.target.value }
                                    })}

                                    value={this.state.pessoaFuncionario.tags0 || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.matricula && "var(--red-600)" }} />
                                {this.state.inputsError.matricula && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Matricula inválida
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>N° do cartão </label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tags1: pTexto.target.value }
                                    })}

                                    value={this.state.pessoaFuncionario.tags1 || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.nCartao && "var(--red-600)" }} />
                                {this.state.inputsError.nCartao && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Número inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>Operadora</label><br />
                                <Dropdown
                                    onChange={pItem => this.setState({ pessoaOperadoraRelacionamento: pItem.value })}
                                    value={this.state.pessoaOperadoraRelacionamento}
                                    options={this.state.listaOperadora}
                                    optionLabel='_pessoaRelacionadaNome'
                                    placeholder='Selecione a operadora'
                                    filter
                                    filterBy="nome"
                                    style={{
                                        width: "100%",
                                        height: 40,
                                    }}
                                />
                            </div>

                            <div>
                                <label>Dias trabalhados</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tagn0: pTexto.target.value }
                                    })}
                                    value={this.state.pessoaFuncionario.tagn0 || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.diasTrabalhado && "var(--red-600)" }} />
                                {this.state.inputsError.diasTrabalhado && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Dias trabalhado inválido
                                    </small>
                                )}
                            </div>


                            <div>
                                <label>Dias das semanas</label><br />
                                <Dropdown
                                    onChange={pItem => this.setState({
                                        pessoaFuncionario: {
                                            ...this.state.pessoaFuncionario, tagn7: pItem.value
                                        }
                                    })}
                                    value={this.state.pessoaFuncionario.tagn7}
                                    options={[{ nome: "Segunda a Sexta", value: 1 }, { nome: "Segunda a Sábado", value: 2 }, { nome: "Segunda a Domingo", value: 3 }]}
                                    optionLabel='nome'
                                    placeholder='Selecione a operadora'
                                    filter
                                    filterBy="nome"
                                    style={{
                                        width: "100%",
                                        height: 40,
                                    }}
                                />
                            </div>


                            <div>
                                <label>Valor diário</label><br />
                                <InputNumber
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tagn1: pTexto.value }
                                    })}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    value={this.state.pessoaFuncionario.tagn1 || ''}
                                    style={{ width: '100%', height: 40 }}
                                    inputStyle={{ borderColor: this.state.inputsError.valorDiario && "var(--red-600)" }}
                                /><br />
                                {this.state.inputsError.valorDiario && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Valor inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>Empresa</label><br />
                                <InputText
                                    disabled
                                    value={this.state.pessoaEmpresa.nome || ''}
                                    style={{ width: '100%', height: 40 }} />
                            </div>
                        </div>
                    </div>

                </form>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
                    <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 1 }}>
                        <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-bookmark" className="p-button-rounded p-button-info p-button-outlined" aria-label="Home" />
                        <h3>Dados do saldo OGT</h3>
                    </div>

                    <div style={{ width: '100%', display: "flex", gap: 20, marginTop: 20 }}>

                        <div>
                            <label>Saldo (OGT)</label><br />
                            <InputNumber
                                disabled
                                onChange={(pTexto) => this.setState({
                                    pessoaFuncionario:
                                        { ...this.state.pessoaFuncionario, tagn2: pTexto.value }
                                })}
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                value={this.state.pessoaFuncionario.tagn2 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Status</label>
                            {this.state.pessoaFuncionario.tags9 === "2" ?
                                <p style={{ color: "orange", marginTop: 12 }}>Aguardando</p> :
                                <p style={{ color: "green", marginTop: 12 }}>Atualizado</p>
                            }
                        </div>

                        <div>
                            <Button
                                style={{ marginTop: 15 }}
                                label="Buscar saldo"
                                disabled={this.state.pessoaFuncionario.tags9 === "2"}
                                loading={this.state.buscarCarregando}
                                className="p-button-raised"
                                onClick={() => this.buscarSaldo()}
                            />
                        </div>


                        <div>
                            <Button
                                style={{ marginTop: 15 }}
                                disabled={this.state.pessoaFuncionario.tags9 === "1"}
                                label="Atualizar saldo"
                                loading={this.state.buscarCarregando}
                                className="p-button-raised"
                                onClick={() => this.AtualizarSaldo()}
                            />
                        </div>
                    </div>

                    <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                        <div>
                            <label>Data atualização(OGT)</label><br />
                            <Calendar
                                disabled
                                value={this.util.formatarDataGerandoNovaData(this.state.pessoaFuncionario.tagd0) || ''}
                                showButtonBar
                                style={{ width: "100%", height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                            />
                        </div>

                        <div>
                            <label>Hora atualização (OGT)</label><br />
                            <InputText
                                disabled
                                value={this.util.formatarHora(this.state.pessoaFuncionario.tags7) || ''}
                                style={{ width: '100%', height: 40, }} />
                        </div>

                        <div>
                            <label>Data consulta</label><br />
                            <Calendar
                                disabled
                                value={this.util.formatarDataGerandoNovaData(this.state.pessoaFuncionario.tagd1) || ''}
                                showButtonBar
                                style={{ width: "100%", height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                            />
                        </div>

                        <div>
                            <label>Hora consulta</label><br />
                            <InputText
                                disabled
                                value={this.util.formatarHora(this.state.pessoaFuncionario.tags8) || ''}
                                style={{ width: '100%', height: 40, }} />
                        </div>
                    </div>
                </div>

            </main>

        </div>
    }
}