import React, { Component } from "react";
import HeaderAdm from "./Components/HeaderAdm";

export default class AdmDocumento extends Component {
  state = {
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {

  }

  render() {
    return (
      <div>
        <HeaderAdm />
        <main style={{ width: "100%", justifyContent:"center", display:"flex" }}>
          <h3>Em desenvolvimento</h3>
        </main>
      </div>
    );
  }
}
