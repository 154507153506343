import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Component } from "react";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";

export default class ContaReceber extends Component {
  constante = new Constante();
  util = new Util();

  state = {
    pessoaUsuario: new Pessoa(),
  }
  componentDidMount() {

  }

  iniciar() {
    const pessoaUsuario = this.util.buscarLocalStorage("PESSOA_USUARIO");
    this.setState({
      pessoaUsuario: pessoaUsuario,
    })
  }
  render() {
    return (
      <div>
        <HeaderAdm />
        <main style={{ width: '95%', margin: '20px auto' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
              <div>
                <h1 style={{ fontSize: 22, }}>Contas á pagar</h1>
              </div>
            </div>
          </div>
          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
              <div>
                <label>Forma de Pagamento</label><br />
                <Dropdown
                  onChange={pEvento => this.setState({ formaPagamento: pEvento.value })}
                  value={this.state.formaPagamento}
                  options={this.state.listaFormaPagamento}
                  optionLabel={'_nome'}
                  style={{ width: '100%', }}
                  placeholder='Selecione' />
              </div>

              <div>
                <label>Status</label><br />
                <Dropdown
                  onChange={pEvento => this.setState({ pago: pEvento.value })}
                  value={this.state.pago}
                  options={[
                    { label: 'Todos', value: "" },
                    { label: 'Pago', value: "S" },
                    { label: 'Não pago', value: "N" }
                  ]}
                  style={{ width: '100%', }}
                  placeholder='Selecione' />
              </div>
              <div>
                <label>Data cadastro inicial</label><br />
                <Calendar
                  onChange={pEvento => this.setState({ dataCadastro_inicial: pEvento.value })}
                  value={this.state.dataCadastro_inicial || ''}
                  style={{ width: '100%', }}
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy" />
              </div>
              <div>
                <label>Data cadastro final</label><br />
                <Calendar
                  showButtonBar
                  onChange={pEvento => this.setState({ dataCadastro_final: pEvento.value })}
                  value={this.state.dataCadastro_final || ''}
                  style={{ width: '100%', }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy" />
              </div>
              <div>
                <label>Data programada inicial</label><br />
                <Calendar
                  showButtonBar
                  onChange={pEvento => this.setState({ dataProgramada_final: pEvento.value })}
                  value={this.state.dataProgramada_final || ''}
                  style={{ width: '100%', }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy" />
              </div>
              <div>
                <label>Data programada final</label><br />
                <Calendar
                  showButtonBar
                  onChange={pEvento => this.setState({ dataProgramada_final: pEvento.value })}
                  value={this.state.dataProgramada_final || ''}
                  style={{ width: '100%', }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy" />
              </div>

              <div>
                <label>Data credito inicial</label><br />
                <Calendar
                  showButtonBar
                  onChange={pEvento => this.setState({ dataCredito_final: pEvento.value })}
                  value={this.state.dataCredito_final || ''}
                  style={{ width: '100%', }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy" />
              </div>

              <div>
                <label>Data credito final</label><br />
                <Calendar
                  showButtonBar
                  onChange={pEvento => this.setState({ dataCredito_final: pEvento.value })}
                  value={this.state.dataCredito_final || ''}
                  style={{ width: '100%', }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy" />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
              <Button
                loading={this.state.carregando}
                label="Buscar"
                className="p-button-raised"
                onClick={() => this.listar()} />
            </div>
          </div>

          <DataTable
            style={{ marginTop: 20 }}
            selectionMode="single"
            showGridlines
            removableSort
            paginator
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
          // value={this.state.listaFinanceiro}
          // onSelectionChange={(pEvento) => window.open(this.constante.url_base_crosspag + pEvento.value._seqMovimentoFinanceiro)}
          >
            <Column field="_pesNome" header="Nome" sortable />
            <Column field="_fpNome" header="Forma pagamento" sortable />
            <Column field="_cfOperacao" header="Operação" sortable />
            <Column
              field="valorProgramado"
              header="Valor"
              sortable
              body={item => <p>{this.util.formatarValor(item.valorProgramado)}</p>} />
            <Column
              field="valorTaxa"
              header="Valor taxa"
              sortable
              body={item => <p>{this.util.formatarValor(item.valorTaxa)}</p>} />
            <Column
              field="valorLiquido"
              header="Valor líquido"
              sortable
              body={item => <p>{this.util.formatarValor(item.valorLiquido)}</p>} />
            <Column
              field="qtdeBaixa"
              header="Status"
              sortable
              body={item => <p>{item.qtdeBaixa > 0 ? 'Pago' : 'Não pago'}</p>} />
            <Column
              field="dataProgramado"
              header="Data programada"
              sortable
              body={item => <p>{this.util.formatarData(item.dataProgramado)}</p>} />
            <Column
              field="_dataCredito"
              header="Data crédito"
              sortable
              body={item => <p>{this.util.formatarData(item._dataCredito)}</p>} />
            <Column
              field="dataCadastro"
              header="Data cadastro"
              sortable
              body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>} />
          </DataTable>
        </main>
      </div>
    )
  }
}