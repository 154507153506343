import React, { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from 'primereact/dialog';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";

export default class DetalheEmpresa extends Component {
    toast = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)


    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        pessoaOperadora: new Pessoa(),
        pessoaEmpresa: new Pessoa(),
        relacionamento: new PessoaRelacionamento(),
        tela: 1,
        tabelaCarregando: false,
        salvarCarregando: false,

        listaCartao: [],
        listaOperadora: [],
        listaOperadoraRelacionada: [],
        senhaOperadora: '',
        loginOperadora: '',
        inputErrorOperadora: {
            loginOperadora: false,
            senhaOperadora: false,
            operadora: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaEmpresa = this.props.pessoaEmpresa;
        console.log('PESSOA', pessoaEmpresa);

        this.listar(pessoaEmpresa);
        this.listarOperadora();
        let listaOperadoraRelacionada = await this.listarRelacionamentoOperadora(pessoaEmpresa);
        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
            pessoaEmpresa: pessoaEmpresa,
            listaOperadoraRelacionada: listaOperadoraRelacionada,
        });

    }

    async listar(pPessoa) {
        this.setState({ tabelaCarregando: true });

        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa_relacionamento.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa_relacionamento.seq_pessoa_relacionada";
        pesquisa.conteudo = pPessoa._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa_relacionamento.seq_tipo_relacionamento";
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresaCartao;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_pessoa_perfil";
        pesquisa.conteudo = this.constante.seqPerfilFuncionarioOK;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaCartao = retorno.objeto;

        console.log(listaPesquisa);
        console.log(listaCartao);

        this.setState({
            listaCartao: listaCartao,
            tabelaCarregando: false,

        })
        return listaCartao;

    }

    async listarOperadora() {
        let listaPesquisa = [

            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPerfilOperadora,
                "AND",
                "TEXTO"
            ),

        ]

        this.retornoListaPequisa = await this.pessoaService.listarComFiltro(listaPesquisa)
        let listaOperadora = this.retornoListaPequisa.objeto
        console.log("lista operadora", listaOperadora);
        this.setState({
            listaOperadora: listaOperadora,
        })
    }

    async salvar() {
        this.setState({ salvarCarregando: true })
        let pessoaEmpresa = this.state.pessoaEmpresa;
        if (!pessoaEmpresa?.tagn0) {
            pessoaEmpresa.tagn0 = 0
        }
        let retorno = await this.pessoaService.salvar(pessoaEmpresa);
        console.log(retorno)

        this.toast.current.show([
            { severity: 'success', summary: ' Alteração armazenadas com sucesso!' },
        ]);
        this.setState({
            salvarCarregando: false
        })
    }

    async salvarOperadora() {
        this.setState({ salvarCarregando: true })
        let pessoaEmpresa = this.state.pessoaEmpresa;
        let pessoaOperadora = this.state.pessoaOperadora;
        let inputErrorOperadora = this.state.inputErrorOperadora;
        let inputsOk = true;

        Object.keys(inputErrorOperadora).forEach(key => {
            inputErrorOperadora[key] = false
        });

        if (!pessoaOperadora?._seqPessoa) {
            inputErrorOperadora.operadora = true;
        }

        if (!this.state.loginOperadora) {
            inputErrorOperadora.loginOperadora = true;
        }

        if (!this.state.senhaOperadora) {
            inputErrorOperadora.senhaOperadora = true;
        }

        this.setState({
            inputErrorOperadora: inputErrorOperadora
        })

        Object.keys(inputErrorOperadora).forEach(key => {
            if (inputErrorOperadora[key]) {
                inputsOk = false
            }
        })

        if (!inputsOk) {
            this.setState({ salvarCarregando: false })
            return
        }

        let pessoaRelacionamento = new PessoaRelacionamento();
        pessoaRelacionamento.seqPessoa = pessoaEmpresa._seqPessoa;
        pessoaRelacionamento.seqPessoaRelacionada = pessoaOperadora._seqPessoa;
        pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
        pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoOperadoraParceira;
        pessoaRelacionamento.tags0 = this.state.loginOperadora;
        pessoaRelacionamento.tags1 = this.state.senhaOperadora;
        pessoaRelacionamento.tagn0 = pessoaOperadora.tagn3;
        let retorno = await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);
        console.log(retorno);

        this.toast.current.show([
            { severity: 'success', summary: ' Alteração armazenadas com sucesso!' },
        ]);

        this.listarRelacionamentoOperadora();

        this.setState({
            pessoaOperadora: new Pessoa(),
            loginOperadora: '',
            senhaOperadora: '',
            salvarCarregando: false,
        })

    }

    async listarRelacionamentoOperadora(pEmpresa) {
        let pessoaEmpresa = this.state.pessoaEmpresa;
        if (pEmpresa) {
            pessoaEmpresa = pEmpresa;
        }

        let listaRelacionamento = []
        let listaOperadoraRelacionada = []

        let retorno = await this.pessoaService.listarRelacionamento(pessoaEmpresa)
        listaRelacionamento = retorno.objeto
        console.log("lista de relacionamento operadora", listaRelacionamento);

        for (let i = 0; i < listaRelacionamento.length; i++) {

            if (listaRelacionamento[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoOperadoraParceira) {
                listaOperadoraRelacionada.push(listaRelacionamento[i])
            }
        }

        this.setState({ listaOperadoraRelacionada: listaOperadoraRelacionada })

        return listaOperadoraRelacionada;
    }

    async deletarRelacionamento() {
        let relacionamento = this.state.relacionamento;
        await this.pessoaService.deletarRelacionamento(relacionamento);
        this.listarRelacionamentoOperadora();
        this.toast.current.show([
            { severity: 'success', summary: 'Operadora deletada' },
        ]);
        this.setState({
            relacionamento: new PessoaRelacionamento(),
            vModalDeletar: false,
        })
    }

    render() {
        return (
            <div>
                <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                    <Dialog
                        header="Atenção deseja deletar a operadora selecionada?"
                        visible={this.state.vModalDeletar}
                        style={{ width: 900 }}
                        onHide={() => this.setState({ vModalDeletar: false, relacionamento: new PessoaRelacionamento() })}
                    >
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px' }}>
                            <div style={{ width: '100%' }}>
                                <label>Operadora</label><br />
                                <InputText
                                    value={this.state.relacionamento._pessoaRelacionadaNome || ''}
                                    disabled
                                />
                            </div>

                            <div style={{ width: '100%' }}>
                                <label>Login</label><br />
                                <InputText
                                    value={this.state.relacionamento.tags0 || ''}
                                    disabled
                                />
                            </div>

                            <div style={{ width: '100%' }}>
                                <label>Senha</label><br />
                                <InputText
                                    value={this.state.relacionamento.tags1 || ''}
                                    disabled
                                />
                            </div>


                            {/* <div style={{ width: '100%' }}>
                                <label>Gordura</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({})}
                                    value={this.state.relacionamento.tags0 || ''}
                                    disabled
                                />
                            </div> */}
                        </div>

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', alignItems: 'center', gap: '20px', marginTop: '20px' }}>
                            <Button onClick={() => this.deletarRelacionamento()} label="Sim" />
                            <Button onClick={() => this.setState({ vModalDeletar: false, relacionamento: new PessoaRelacionamento() })} label="Não" />
                        </div>
                    </Dialog>
                    <Toast ref={this.toast} />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>

                        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <Button
                                label={<i className="pi pi-arrow-left" />}
                                onClick={() => {
                                    this.props.listar()
                                    this.props.setState({
                                        pessoa: new Pessoa(),
                                        tela: 1
                                    })
                                }} />
                            <h1 style={{ fontSize: 22.5 }}>Detalhe da Empresa</h1>
                        </div>


                        <Button
                            label="Salvar"
                            loading={this.state.salvarCarregando}
                            onClick={() => this.salvar()} />
                    </div>
                    <div style={{ display: "flex", padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5, marginTop: 20, gap: 20 }}>
                        <div style={{ width: 200, height: 200, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                            {this.state.pessoa?.foto ?
                                <img
                                    src={this.state.urlBase + this.state.pessoa.foto}
                                    style={{ width: 200, height: 200, objectFit: 'contain' }} />
                                :
                                <p>sem imagem</p>
                            }
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", marginTop: 20, gap: 20 }}>
                            <div>
                                <label>Nome</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoaEmpresa?.nome || ""} />
                            </div>
                            <div>
                                <label>CNPJ</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoaEmpresa?.documento || ""} />
                            </div>
                            <div>
                                <label>Email</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoaEmpresa?.emailPlataformaLirida || ""} />
                            </div>
                            <div>
                                <label>Telefone</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoaEmpresa?.telefonePlataformaLirida || ""} />
                            </div>

                            <div>
                                <label>Data Cadastro Inicial</label><br />
                                <Calendar
                                    disabled
                                    showButtonBar
                                    style={{ width: "100%", height: 40 }}
                                    placeholder="dd/mm/yyyy"
                                    value={this.util.formatarDataCalendar(this.state.pessoaEmpresa?.dataCadastro) || ""}
                                />
                            </div>

                            <div>
                                <label>Dias de margem</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, tagn0: this.util.formatarNumero(pTexto.target.value)
                                        }
                                    })}
                                    style={{ width: '100%', }}
                                    value={this.state.pessoaEmpresa.tagn0 || ""} />
                            </div>
                        </div>
                    </div>
                    <TabView style={{ marginTop: 10 }}>
                        <TabPanel header='Cartões'>
                            <DataTable
                                selectionMode="single"
                                showGridlines
                                removableSort
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                loading={this.state.tabelaCarregando}
                                style={{ width: "100%" }}
                                value={this.state.listaCartao}
                                onSelectionChange={e => this.selecionar(e.value)}
                            >
                                <Column header='Matricula' field='tags0' sortable />
                                <Column header='Nome' field='nome' sortable />
                                <Column header='CPF' field='documento' sortable />
                                <Column header='N. Cartão' field='tags1' sortable />
                                <Column header='Operadora' field='tags2' sortable />
                                <Column header='Data cadastro' sortable body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />
                            </DataTable>
                        </TabPanel>
                        <TabPanel header='Operadora'>
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-around', gap: 20, }}>
                                    <div>
                                        <label>Operadora</label><br />
                                        <Dropdown
                                            onChange={pItem => this.setState({ pessoaOperadora: pItem.value })}
                                            value={this.state.pessoaOperadora}
                                            options={this.state.listaOperadora}
                                            optionLabel='nome'
                                            placeholder='Selecione a operadora'
                                            filter
                                            filterBy="nome"
                                            style={{
                                                width: "100%",
                                                height: 40,
                                                borderColor: this.state.inputErrorOperadora.operadora && 'var(--red-600)'
                                            }}
                                        />
                                        {this.state.inputErrorOperadora.operadora && <small style={{ color: 'var(--red-600)' }}>Operadora inválido</small>}
                                    </div>
                                    <div>
                                        <label>Login operadora</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({ loginOperadora: pTexto.target.value })}
                                            style={{
                                                width: "100%",
                                                height: 40,
                                                borderColor: this.state.inputErrorOperadora.loginOperadora && 'var(--red-600)'
                                            }}
                                            value={this.state.loginOperadora || ""} />
                                        {this.state.inputErrorOperadora.loginOperadora && <small style={{ color: 'var(--red-600)' }}>Login inválido</small>}
                                    </div>


                                    <div>
                                        <label>Senha operadora</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({ senhaOperadora: pTexto.target.value })}
                                            style={{
                                                width: "100%",
                                                height: 40,
                                                borderColor: this.state.inputErrorOperadora.loginOperadora && 'var(--red-600)'
                                            }}
                                            value={this.state.senhaOperadora || ""} />
                                        {this.state.inputErrorOperadora.senhaOperadora && <small style={{ color: 'var(--red-600)' }}>Senha inválido</small>}
                                    </div>

                                </div>
                                <div style={{ width: '40%', justifyContent: 'flex-start', marginLeft: 10 }}>

                                    <Button
                                        loading={this.state.salvarCarregando}
                                        label="Salvar"
                                        onClick={() => this.salvarOperadora()} />
                                </div>
                            </div>
                            <DataTable
                                selectionMode="single"
                                showGridlines
                                removableSort
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                loading={this.state.tabelaCarregando}
                                style={{ width: "100%", marginTop: 20 }}
                                value={this.state.listaOperadoraRelacionada}
                            >
                                <Column header='Operadora' field='_pessoaRelacionadaNome' sortable />
                                <Column header='Login' field='tags0' sortable />
                                <Column header='Senha' field='tags1' sortable />
                                <Column header='Deletar' field='tags1' sortable body={(item) => <Button
                                    icon="pi pi-trash"
                                    onClick={() => this.setState({ vModalDeletar: true, relacionamento: item })} />} />
                            </DataTable>
                        </TabPanel>
                    </TabView>

                </main>
            </div >
        );
    }
}
