import React, { Component, createRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { InputNumber } from "primereact/inputnumber";
import { Link, Navigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import PessoaAtendimento from 'lirida-back-service/Servicos/PessoaAtendimento/PessoaAtendimento';
import HeaderAdm from "./Components/HeaderAdm";
import Meses from "../../Service/Meses";
import { PedidoItemOgt } from "../../Service/PedidoItemOgt";
import { PedidoOgt } from "../../Service/PedidoOgt";
import { OgtService } from "../../Service/OgtService";

export default class EmpresaCartao extends Component {
  menu = createRef();
  toast = createRef();
  constante = new Constante();
  mensagem = createRef();
  mensagemCorrecao = createRef();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  ogtService = new OgtService(this.constante.url_base_ogt);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEmpresa: new Pessoa(),
    pessoaOperadora: new PessoaRelacionamento(),
    pessoaFuncionario: new Pessoa(),
    PessoaSuperAdmin: new Pessoa(),
    PessoaAtendimento: new PessoaAtendimento(),
    meses: new Meses(),
    mesSelecionado: null,
    cartaoSelecionado: null,
    listaCartaoSelecionado: [],

    //Modal
    vModalNovo: false,
    vModalRelatorio: false,
    vModaExcel: false,
    vModalErrorOgt: false,
    vModalCalculo: false,
    vModalCalculoDias: false,
    vModalDeletarCartao: false,
    buscarCarregando: false,
    desmarcarTodosCarregando: false,
    marcarTodosCarregando: false,
    salvarExcelCarregando: false,
    salvarCarregando: false,
    tabelaCarregando: false,
    calcularDiaCarregando: false,
    calcularEconomiaCarregando: false,
    arquivoExcel: null,
    liberarAcesso: {
      detalheCartao: false,
    },

    listaEmpresa: [],
    listaCartao: [],
    listaOperadoraRelacionada: [],

    indexTab: 0,

    inputError: {
      nome: false,
      cpf: false,
      matricula: false,
      numeroCartao: false,
      empresa: false,
      arquivo: false,
      operadora: false,
      loginOperadora: false,
      senhaOperadora: false,
      diasTrabalhado: false,
      valorDiario: false,
      diasSemanais: false
    },

    inputErrorFiltro: {
      empresa: false
    },


    filtros: {
      nome: '',
      dataCadastro_inicial: '',
      dataCadastro_final: '',
      dataAtualizacao_inicial: '',
      dataAtualizacao_final: '',
      // seqPerfil: '',
      documento: '',
      pessoaEmpresa: null,
      status: '',
      inconsistencia: '',
      marcado: '',
      matricula: '',
    },

    itens: [
      {
        label: 'Opções',
        items: [
          {
            label: 'Novo',
            icon: 'pi pi-user-plus',
            command: () => {
              if (this.state.pessoaEmpresa?._seqPessoa) {
                this.setState({ vModalNovo: true });
                this.listarRelacionamentoOperadora()
              } else {
                this.toast.current.show([
                  {
                    severity: 'warn', summary: 'Nenhuma empresa selecionada',
                    detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
                  }])
              }

            }
          },
          {
            label: 'Importar Excel',
            icon: 'pi pi-file-excel',
            command: () => {
              if (this.state.pessoaEmpresa?._seqPessoa) {
                this.setState({ vModaExcel: true })
              } else {
                this.toast.current.show([
                  {
                    severity: 'warn', summary: 'Nenhuma empresa selecionada',
                    detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
                  }])
              }
            }
          },
          {
            label: 'Buscar saldo',
            icon: ' pi pi-search-plus',
            command: () => {
              if (this.state.pessoaEmpresa?._seqPessoa) {
                this.buscarSaldoEmLote();
              } else {
                this.toast.current.show([
                  {
                    severity: 'warn', summary: 'Nenhuma empresa selecionada',
                    detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
                  }])
              }
            }
          },
          {
            label: 'Atualizar saldo',
            icon: 'pi pi-refresh',
            command: () => {
              if (this.state.pessoaEmpresa?._seqPessoa) {
                this.atualizarSaldoEmLote()
              } else {
                this.toast.current.show([
                  {
                    severity: 'warn', summary: 'Nenhuma empresa selecionada',
                    detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
                  }])
              }
            }
          },
          {
            label: 'Calcular dias',
            icon: 'pi pi-calendar-plus',
            command: () => {
              if (this.state.pessoaEmpresa?._seqPessoa) {
                this.setState({ vModalCalculoDias: true, });

              } else {
                this.toast.current.show([
                  {
                    severity: 'warn', summary: 'Nenhuma empresa selecionada',
                    detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
                  }])
              }
            }
          },
          {
            label: 'Calcular economia',
            icon: 'pi pi-money-bill',
            command: () => {
              if (this.state.pessoaEmpresa?._seqPessoa) {
                this.setState({ vModalCalculo: true, dataBase: new Date() })
                this.obterDiasRestantesDoMes(new Date());
              } else {
                this.toast.current.show([
                  {
                    severity: 'warn', summary: 'Nenhuma empresa selecionada',
                    detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
                  }])
              }
            }
          },

          {
            label: 'Gerar arquivo pedido',
            icon: 'pi pi-file',
            command: () => {
              if (this.state.pessoaEmpresa?._seqPessoa) {
                this.gerarPedidoTxt();
              } else {
                this.toast.current.show([
                  {
                    severity: 'warn', summary: 'Nenhuma empresa selecionada',
                    detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
                  }])
              }
            }
          },
        ]
      },
    ],


    mensagemErrorOgt: '',

    diasRestante: null,
    valorMensal: null,
    totalCartao: null,
    valorProxPeriodo: null,
    complemento: null,
    economia: null,
    dataBase: '',

    // calculo de dias
    ano: '',
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    const pessoaEmpresa = await this.util.buscarLocalStorage("PESSOA_EMPRESA");
    let listaOperadoraRelacionada = await this.listarRelacionamentoOperadora(pessoaEmpresa);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaEmpresa: pessoaEmpresa,
      listaOperadoraRelacionada: listaOperadoraRelacionada
    })
  }

  async salvar() {
    this.setState({ salvarCarregando: true })
    this.mensagem.current.clear();
    let pessoaFuncionario = this.state.pessoaFuncionario;
    pessoaFuncionario.seqUsuario = this.constante.seqUsuario;
    pessoaFuncionario.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;
    pessoaFuncionario.tipoDocumento = "CPF";
    pessoaFuncionario.situacao = "ATIVO";
    pessoaFuncionario.tags2 = this.state.pessoaOperadora._pessoaRelacionadaNome;
    pessoaFuncionario.tags3 = 'N';
    pessoaFuncionario.tagn3 = this.state.pessoaOperadora.tagn0;
    pessoaFuncionario.tags5 = this.state.pessoaOperadora.tags0;
    pessoaFuncionario.tags6 = this.state.pessoaOperadora.tags1;
    pessoaFuncionario.tagn0 = Number(pessoaFuncionario.tagn0);
    let retorno = await this.pessoaService.salvar(pessoaFuncionario);
    pessoaFuncionario = retorno.objeto;

    let pessoaRelacionamento = new PessoaRelacionamento();
    pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
    pessoaRelacionamento.seqPessoa = pessoaFuncionario._seqPessoa;
    pessoaRelacionamento.seqPessoaRelacionada = this.state.pessoaEmpresa._seqPessoa;
    pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEmpresaCartao;
    retorno = await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);
    console.log(retorno);

    this.listar(pessoaFuncionario, this.state.pessoaEmpresa);

    this.resetar();
    this.mensagem.current.show([
      {
        severity: "success",
        summary: "Cartão cadastrado com sucesso.",
        sticky: true,
      },
    ]);
  }

  resetar() {
    let inputError = this.state.inputError;
    let listaKey = Object.keys(inputError);
    listaKey.forEach(key => inputError[key] = false);
    this.setState({
      vModalNovo: false,
      inputError: inputError,
      salvarCarregando: false,
      arquivoExcel: null,
      pessoaFuncionario: new Pessoa(),
      pessoaOperadora: new PessoaRelacionamento(),
    })
  }

  async listar(pPessoaUsuario, pPessoaEmpresa) {
    this.setState({ tabelaCarregando: true });
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario;
    }

    let pessoaEmpresa = this.state.pessoaEmpresa;
    if (pPessoaEmpresa) {
      pessoaEmpresa = pPessoaEmpresa;
    }

    if (!pessoaEmpresa?._seqPessoa) {
      this.toast.current.clear();
      this.toast.current.show([
        {
          severity: 'warn', summary: 'Nenhuma empresa selecionada',
          detail: 'Por favor, selecione uma empresa para continuar a operação', sticky: false
        },
      ]);
      this.setState({ tabelaCarregando: false });
      return
    }

    const listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresaCartao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
    pesquisa.conteudo = pessoaEmpresa._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = "pessoa.seq_pessoa_perfil";
    pesquisa.conteudo = this.constante.seqPerfilFuncionarioOK;
    pesquisa.operacao = "AND";
    pesquisa.tipo = "TEXTO";
    listaPesquisa.push(pesquisa);



    if (this.state.filtros.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.nome";
      pesquisa.conteudo = this.state.filtros.nome;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.documento) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.documento";
      pesquisa.conteudo = this.state.filtros.documento;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }


    if (this.state.filtros.dataCadastro_inicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.data_cadastro";
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtros.dataCadastro_inicial);
      pesquisa.operacao = "AND";
      pesquisa.tipo = "DATA_INICIAL";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.dataCadastro_final) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.data_cadastro";
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtros.dataCadastro_final);
      pesquisa.operacao = "AND";
      pesquisa.tipo = "DATA_FINAL";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.dataAtualizacao_inicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.tagd0";
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtros.dataAtualizacao_inicial);
      pesquisa.operacao = "AND";
      pesquisa.tipo = "DATA_INICIAL";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.dataAtualizacao_final) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.tagd0";
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtros.dataAtualizacao_final);
      pesquisa.operacao = "AND";
      pesquisa.tipo = "DATA_FINAL";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.marcado) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.tags3";
      pesquisa.conteudo = this.state.filtros.marcado;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.matricula) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.tags0";
      pesquisa.conteudo = this.state.filtros.matricula;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.status) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.tags9";
      pesquisa.conteudo = this.state.filtros.status;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.inconsistencia) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.tags4";
      pesquisa.conteudo = this.state.filtros.inconsistencia;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaCartao = retorno.objeto;

    console.log(listaPesquisa);
    console.log(listaCartao);

    this.setState({
      listaCartao: listaCartao,
      tabelaCarregando: false,
    })
    return listaCartao;
  }

  async listarRelacionamentoOperadora(pEmpresa) {
    let pessoaEmpresa = this.state.pessoaEmpresa;
    if (pEmpresa) {
      pessoaEmpresa = pEmpresa;
    }

    let listaRelacionamento = []
    let listaOperadoraRelacionada = []

    let retorno = await this.pessoaService.listarRelacionamento(pessoaEmpresa)
    listaRelacionamento = retorno.objeto
    console.log("lista de relacionamento operadora", listaRelacionamento);

    for (let i = 0; i < listaRelacionamento.length; i++) {
      if (listaRelacionamento[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoOperadoraParceira) {
        listaOperadoraRelacionada.push(listaRelacionamento[i])
      }
    }

    this.setState({ listaOperadoraRelacionada: listaOperadoraRelacionada })
    return listaOperadoraRelacionada;
  }

  validarInputs() {
    let inputError = this.state.inputError;
    console.log(inputError);
    let listaKey = Object.keys(inputError);
    let inputOk = true;
    listaKey.forEach(key => inputError[key] = false);

    if (!this.state.pessoaFuncionario.nome) {
      inputError.nome = true
    }

    if (!this.state.pessoaFuncionario.documento) {
      inputError.cpf = true
    }

    if (this.state.pessoaFuncionario.documento && this.state.pessoaFuncionario.documento < 14) {
      inputError.cpf = true
    }

    if (!this.state.pessoaFuncionario.tags0) {
      inputError.matricula = true;
    }


    if (!this.state.pessoaFuncionario.tags1) {
      inputError.numeroCartao = true;
    }


    if (!this.state.pessoaOperadora._seqPessoaRelacionamento) {
      inputError.operadora = true;
    }

    if (!this.state.pessoaFuncionario.tagn0) {
      inputError.diasTrabalhado = true;
    }

    if (!this.state.pessoaFuncionario.tagn1) {
      inputError.valorDiario = true;
    }

    if (!this.state.pessoaFuncionario.tagn7) {
      inputError.diasSemanais = true;
    }

    if (!this.state.pessoaEmpresa._seqPessoa) {
      inputError.empresa = true;
    }

    listaKey.forEach(key => {
      if (inputError[key]) {
        inputOk = false
      }
    })

    this.setState({ inputError: inputError })
    return inputOk
  }

  limparInputsError() {
    let inputError = this.state.inputError;
    let listaKey = Object.keys(inputError);
    listaKey.forEach(key => {
      inputError[key] = false;
    });
    this.setState({ inputError: inputError });
  }

  ValidarInputFiltro() {
    let inputErrorFiltro = this.state.inputErrorFiltro;
    console.log(inputErrorFiltro);
    let listaKey = Object.keys(inputErrorFiltro);
    let inputOk = true;
    listaKey.forEach(key => inputErrorFiltro[key] = false);


    if (!this.state.filtros.pessoaEmpresa) {
      inputErrorFiltro.empresa = true;
    }

    listaKey.forEach(key => {
      if (inputErrorFiltro[key]) {
        inputOk = false
      }
    })

    this.setState({ inputErrorFiltro: inputErrorFiltro })
    return inputOk

  }

  selecionar(pPessoaFuncionario) {
    let pessoaFuncionario = pPessoaFuncionario
    console.log('funcionario selecionado', pessoaFuncionario);
    this.setState({ pessoaFuncionario: pessoaFuncionario })
    this.navegar('detalheCartao')
  }

  //--- LOTE  E SALDO ----- //
  async buscarSaldoEmLote() {
    this.setState({ buscarCarregando: true })
    let listaCartao = this.state.listaCartao;
    const listaCartaoSelecionado = [];
    for (let i = 0; i < listaCartao.length; i++) {
      if (listaCartao[i].tags3 === 'S') {
        listaCartaoSelecionado.push(listaCartao[i])
      }
    }

    if (!listaCartaoSelecionado.length) {
      this.setState({ buscarCarregando: false })
      return
    }

    let retornoAdm = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaSuperAdmin);
    let pessoaSuperAdmin = retornoAdm.objeto;
    const pessoaEmpresa = this.state.pessoaEmpresa;
    const listaItem = [];
    let idPedido = pessoaSuperAdmin?.tagn0 + 1;
    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN", token);

    pessoaSuperAdmin.tagn0 = idPedido;
    retornoAdm = await this.pessoaService.salvar(pessoaSuperAdmin);
    console.log("PESSO ADM", retornoAdm.objeto);

    listaCartaoSelecionado.forEach(async cartao => {
      let pedidoItemOgt = new PedidoItemOgt();
      pedidoItemOgt.nomeFuncionario = cartao.nome;
      pedidoItemOgt.cpf = cartao.documento;
      pedidoItemOgt.numeroCartao = cartao.tags1;
      pedidoItemOgt.idOperadora = cartao.tagn3;
      pedidoItemOgt.loginOperadora = pessoaEmpresa.tags5;
      pedidoItemOgt.senhaOperadora = pessoaEmpresa.tags6;
      pedidoItemOgt.cdempresa = this.constante.cdEmpresa;
      listaItem.push(pedidoItemOgt);
      cartao.tagn4 = idPedido;
      cartao.tags9 = '2';
      let retorno = await this.pessoaService.salvar(cartao);
      console.log("PESSOA SALVA", retorno.objeto)
    })

    let pedidoOgt = new PedidoOgt();
    pedidoOgt.idArquivo = this.constante.idArquivo;
    pedidoOgt.pedidoItens = listaItem;
    pedidoOgt.idPedido = idPedido;
    pedidoOgt.nrSolicitacao = 0;
    console.log('Pedido', pedidoOgt);
    retornoOgt = await this.ogtService.enviarLote(pedidoOgt, token);
    console.log("lote", retornoOgt);
    if (retornoOgt?.errors) {
      for (let i = 0; i < listaCartaoSelecionado.length; i++) {
        listaCartaoSelecionado[i].tags9 = '1';
        let retorno = await this.pessoaService.salvar(listaCartaoSelecionado[i]);
        console.log('CARTAO erro', listaCartaoSelecionado[i]);
      }
      this.setState({ buscarCarregando: false, vModalErrorOgt: true, mensagemErrorOgt: retornoOgt?.errors[0] });
      return
    }
    this.toast.current.show([
      { severity: 'success', summary: 'Saldo buscado', detail: `Saldo buscado com sucesso`, sticky: false },
    ]);
    this.setState({ buscarCarregando: false })
    let date = new Date();
    let pessoaAtendimento = new PessoaAtendimento();
    pessoaAtendimento.seqPessoa = this.state.pessoaUsuario._seqPessoa;
    pessoaAtendimento.seqTipoAtendimento = this.constante.seqTipoAtendimentoRequisicaoOGT;
    pessoaAtendimento.descricao = `${listaItem?.length} \n ${date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`;
    let retornoAtendimento = await this.pessoaService.salvarAtendimento(pessoaAtendimento);
    console.log('ATENDIMENTO', retornoAtendimento);
  }

  async atualizarSaldoEmLote() {
    this.setState({ buscarCarregando: true })
    let listaCartao = this.state.listaCartao;
    const listaComNumeroPedido = [];
    this.toast.current.clear();

    listaCartao.forEach(cartao => {
      if (cartao.tags9 === '2') {
        listaComNumeroPedido.push(cartao.tagn4);
      }
    })

    if (!listaComNumeroPedido.length) {
      this.setState({ buscarCarregando: false })
      return
    }

    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN", token);

    let listaNumeroPedidoSemDuplicidade = [...new Set(listaComNumeroPedido)];

    for (const numeroPedido of listaNumeroPedidoSemDuplicidade) {
      retornoOgt = await this.ogtService.obterResultado(numeroPedido, 0, token);
      console.log("retornoOGT", retornoOgt);
      if (!retornoOgt.data) {
        this.toast.current.show([
          { severity: 'warn', summary: 'Informativo', detail: `${retornoOgt.statusPedido} `, sticky: false },
        ]);
        this.setState({ buscarCarregando: false })
        return
      }
      let listaOgt = retornoOgt.data;
      for (let i = 0; i < listaCartao.length; i++) {
        for (let j = 0; j < listaOgt.length; j++) {
          if (listaOgt[j].numeroCartao === this.util.removerZeroECaracter(listaCartao[i].tags1)) {
            listaCartao[i].tagn2 = listaOgt[j].saldo;
            listaCartao[i].tagn5 = listaOgt[j].saldoDesmembrado;
            listaCartao[i].tagn6 = listaOgt[j].saldoPendenteDesmembrado;
            listaCartao[i].tags7 = this.util.formatarHora(listaOgt[j].dataUltimaAtualizacao.split("T")[1]);
            listaCartao[i].tags8 = this.util.formatarHora(listaOgt[j].dataProcessamento.split("T")[1]);
            listaCartao[i].tagd0 = this.util.converterDataRegex(listaOgt[j].dataUltimaAtualizacao);
            listaCartao[i].tagd1 = this.util.converterDataRegex(listaOgt[j].dataProcessamento);
            listaCartao[i].tags9 = '1';
            let retorno = await this.pessoaService.salvar(listaCartao[i]);
            console.log('PESSOA SALVA', retorno)
          }
        }
      }
    }
    this.setState({ buscarCarregando: false });
    this.toast.current.show([
      { severity: 'success', summary: 'Saldo atualizado', detail: `Saldo atualizado com sucesso`, sticky: false },
    ]);
  }
  // ---------------- //


  // ----- EXCEL ------ //
  gerenciarArquivoExcel(pArquivo) {
    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (pArquivo) {
      if (pArquivo && fileType.includes(pArquivo.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(pArquivo);
        reader.onload = (e) => {
          this.toast.current.clear();
          this.importarListaExcel(e.target.result);
        }
      }
      else {
        this.toast.current.show([
          { severity: 'error', summary: 'Por favor, selecione um arquivo Excel!', sticky: true },
        ]);
        this.setState({
          arquivoExcel: null
        })
      }
    }
  }

  async importarListaExcel(pArquivoExcel) {
    this.mensagem.current.clear();
    const workbook = XLSX.read(pArquivoExcel, { type: 'buffer' });
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    let listaFuncionarioExcel = XLSX.utils.sheet_to_json(worksheet);
    this.setState({ salvarExcelCarregando: true });

    for (let i = 0; i < listaFuncionarioExcel.length; i++) {
      let pessoa = new Pessoa();

      let retornoPessoa = await this.buscarPessoaPorDocumento(listaFuncionarioExcel[i]?.['CPF']?.toString());
      console.log('PESSOA BUSCADO POR DOCUMENTO: ', retornoPessoa);
      if (!retornoPessoa?._seqPessoa) {
        pessoa.documento = listaFuncionarioExcel[i]?.['CPF']?.toString();
        pessoa.nome = listaFuncionarioExcel[i]?.['Nome']?.toString();
        pessoa.tags0 = listaFuncionarioExcel[i]?.['Mat.']?.toString();
        pessoa.tags1 = listaFuncionarioExcel[i]?.['Núm do Cartão']?.toString();
        pessoa.tagn1 = listaFuncionarioExcel[i]?.['Valor Diário'];
        pessoa.tagn0 = listaFuncionarioExcel[i]?.['Dias Trab'];
        pessoa.tagn7 = listaFuncionarioExcel[i]?.['Dias da semana'];
        pessoa.tags2 = listaFuncionarioExcel[i]?.['Nome da operadora'];
        pessoa.tagn3 = listaFuncionarioExcel[i]?.['Id da operadora'];
        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;

        console.log('PESSOA SALVANDO', pessoa);
        if (!pessoa.documento) {
          this.toast.current.show([
            { severity: 'warn', summary: 'Houve algum erro na importação', detail: `Certifique-se de que a tabela esteja correta e tente novamente.`, sticky: true },
          ]);
          return
        }
        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        let relacionamento = new PessoaRelacionamento();
        relacionamento.seqUsuario = this.constante.seqUsuario;
        relacionamento.seqPessoa = pessoa._seqPessoa;
        relacionamento.seqPessoaRelacionada = this.state.pessoaEmpresa._seqPessoa;
        relacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEmpresaCartao;
        this.pessoaService.salvarRelacionamento(relacionamento);
      } else {
        pessoa = retornoPessoa;
        pessoa.documento = listaFuncionarioExcel[i]?.['CPF']?.toString();
        pessoa.nome = listaFuncionarioExcel[i]?.['Nome']?.toString();
        pessoa.tags0 = listaFuncionarioExcel[i]?.['Mat.']?.toString();
        pessoa.tags1 = listaFuncionarioExcel[i]?.['Núm do Cartão']?.toString();
        pessoa.tagn1 = listaFuncionarioExcel[i]?.['Valor Diário'];
        pessoa.tagn0 = listaFuncionarioExcel[i]?.['Dias Trab'];
        pessoa.tagn7 = listaFuncionarioExcel[i]?.['Dias da semana'];
        pessoa.tags2 = listaFuncionarioExcel[i]?.['Nome da operadora'];
        pessoa.tagn3 = listaFuncionarioExcel[i]?.['Id da operadora'];
        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;

        console.log('PESSOA SALVANDO', pessoa);
        if (!pessoa.documento) {
          this.toast.current.show([
            { severity: 'warn', summary: 'Houve algum erro na importação', detail: `Certifique-se de que a tabela esteja correta e tente novamente.`, sticky: true },
          ]);
          return
        }
        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;
      }

      let listaOperadoraRelacionada = await this.listarRelacionamentoOperadora(this.state.pessoaEmpresa);
      for (let j = 0; j < listaOperadoraRelacionada.length; j++) {
        if (listaOperadoraRelacionada[j].tagn0 === pessoa.tagn3) {
          pessoa.tags5 = listaOperadoraRelacionada[j].tags0;
          pessoa.tags6 = listaOperadoraRelacionada[j].tags1;
          await this.pessoaService.salvar(pessoa);
        }
      }
    }
    this.setState({
      vModalRelatorio: false,
      vModaExcel: false,
      salvarExcelCarregando: false,
    })
    this.toast.current.show([
      { severity: 'success', summary: 'Cartões cadastrados', detail: `Cartões cadastrados com sucesso`, sticky: false },
    ]);
    this.resetar();
  }

  async buscarPessoaPorDocumento(pDocumento) {
    const listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresaCartao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
    pesquisa.conteudo = this.state.pessoaEmpresa._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.documento';
    pesquisa.conteudo = pDocumento;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);
    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    if (retorno.objeto[0]?._seqPessoa) {
      retorno = await this.pessoaService.buscarPorSeqPessoa(retorno.objeto[0]._seqPessoa);
      return retorno.objeto
    } else {
      return false
    }
  }

  exportExcel() {
    let listaCartao = this.state.listaCartao;
    if (listaCartao.length < 1) {
      return
    }
    const lista = [];
    listaCartao.forEach(cartao => {
      let objeto = {};
      objeto.selecionado = cartao?.tags3;
      objeto.nome = cartao?.nome;
      objeto.cpf = cartao?.documento;
      objeto.matricula = cartao?.tags0;
      objeto.numeroCartao = cartao?.tags1;
      objeto.operadora = cartao?.tags2;
      objeto.DiasDaSemana = cartao.tagn7 === 1 ? 'Segunda a Sexta' : cartao.tagn7 === 2 ? 'Segunda a Sábado' : cartao.tagn7 === 3 ? 'Segunda a Domingo' : '';
      objeto.saldoRecarga = cartao?.tagn6;
      objeto.saldoUso = cartao?.tagn5;
      objeto.saldoTotal = cartao?.tagn2;
      objeto.diasTrabalhados = cartao?.tagn0;
      objeto.valorDiario = cartao?.tagn1;
      objeto.valorMensal = cartao?.tagn0 * cartao?.tagn1;
      objeto.diasRestante = cartao?.tagn10;
      objeto.economia = cartao?.tagn8;
      objeto.complemento = cartao?.tagn9;
      objeto.dataAtualizacaoDoSaldo = this.util.formatarDataComTimestamp(cartao?.tagd0);
      objeto.horaAtualizacaoDoSaldo = cartao?.tags7;
      objeto.dataConsulta = this.util.formatarDataComTimestamp(cartao?.tagd1);
      objeto.horaConsulta = cartao?.tags8;
      objeto.dataCadastro = this.util.formatarDataComTimestamp(cartao?.dataCadastro);
      objeto.statusBuscaDeSaldo = cartao?.tags9 === '2' ? 'Aguardando' : 'Atualizado';
      objeto.inconsistencia = cartao?.tags4;
      objeto.empresa = cartao?._pessoaRelacionadaNome;
      lista.push(objeto)
    })
    listaCartao = lista;

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listaCartao);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      this.saveAsExcelFile(excelBuffer, `base_${this.state.pessoaEmpresa?.nome}_cartoes`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  }
  // ----------------------------------//


  async marcarCartao(pItem) {
    let pessoaFuncionario = pItem;
    let listaCartao = this.state.listaCartao;
    console.log(pItem);
    if (pessoaFuncionario.tags3 === 'S') {
      pessoaFuncionario.tags3 = 'N';
    } else {
      pessoaFuncionario.tags3 = 'S';
    }
    let retorno = await this.pessoaService.salvar(pessoaFuncionario);
    for (const cartao of listaCartao) {
      if (cartao._seqPessoa === pessoaFuncionario._seqPessoa) {
        cartao.tags3 = pessoaFuncionario.tags3;
      }
    }
    this.setState({
      listaCartao: listaCartao
    })
  }

  async marcarTodos() {
    let listaCartao = this.state.listaCartao;
    this.setState({ marcarTodosCarregando: true })
    for (const cartao of listaCartao) {
      cartao.tags3 = 'S';
      await this.pessoaService.salvar(cartao);
    }
    this.setState({ listaCartao: listaCartao, marcarTodosCarregando: false })
  }

  async desmarcarTodos() {
    let listaCartao = this.state.listaCartao;
    this.setState({ desmarcarTodosCarregando: true });
    for (const cartao of listaCartao) {
      cartao.tags3 = 'N';
      await this.pessoaService.salvar(cartao);
    }
    this.setState({ listaCartao: listaCartao, desmarcarTodosCarregando: false, })
  }

  navegar(pTela) {
    const liberarAcesso = this.state.liberarAcesso;
    liberarAcesso[pTela] = true;
    this.setState({ liberarAcesso: liberarAcesso });
  }

  async calcularDias(pAno, pMes) {
    this.setState({ calcularDiaCarregando: true });
    let mes = this.state.mesSelecionado;
    if (pMes) {
      mes = pMes
    }
    let ano = this.state.ano
    if (pAno) {
      ano = pAno;
    }

    let diasNoMes = new Date(Number(ano), mes + 1, 0).getDate();
    let quantidadeDiasUteis = 0;
    let quantidadeSabado = 0;
    let quantidadeDomingo = 0;

    for (let i = 1; i <= diasNoMes; i++) {
      let diaAtual = new Date(ano, mes, i);
      let diaDaSemana = diaAtual.getUTCDay();
      if (diaDaSemana === 1 || diaDaSemana === 2 || diaDaSemana === 3 || diaDaSemana === 4 || diaDaSemana === 5) {
        quantidadeDiasUteis++;
      } else if (diaDaSemana === 6) {
        quantidadeSabado++;
      } else if (diaDaSemana === 0) {
        quantidadeDomingo++;
      }
    }

    let diasTrabalhado;
    let quantidade = 0;
    let listaCartao = this.state.listaCartao;

    for (const cartao of listaCartao) {
      if (cartao.tags3 === 'S') {
        quantidade = quantidade + 1;
        // if (cartao.tag)
        if (!cartao.tagn7) {
          diasTrabalhado = cartao.tagn0;
        }
        if (cartao.tagn7 === 1) {
          diasTrabalhado = quantidadeDiasUteis
        }

        if (cartao.tagn7 === 2) {
          diasTrabalhado = quantidadeDiasUteis + quantidadeSabado
        }

        if (cartao.tagn7 === 3) {
          diasTrabalhado = quantidadeDiasUteis + quantidadeSabado + quantidadeDomingo
        }
        cartao.tagn0 = diasTrabalhado;
        await this.pessoaService.salvar(cartao);
      }
    }

    if (quantidade <= 0) {
      this.toast.current.show([{
        severity: 'warn', summary: 'Nenhum cartão selecionado', detail: 'Selecione os cartões desejados.', sticky: false
      }])
    } else {
      this.toast.current.show([{
        severity: 'success', summary: 'Dias trabalhado atualizado', detail: 'Os dias trabalhados foram atualizados.', sticky: false
      }])
    }

    console.log(listaCartao);
    this.setState({ listaCartao: listaCartao, vModalCalculoDias: false, calcularDiaCarregando: false, });
  }

  calcularEconomia() {
    this.setState({ calcularEconomiaCarregando: true });
    let dataRetorno = this.obterDiasRestantesDoMes(this.state.dataBase);
    let gordura = this.state.pessoaEmpresa.tagn0;
    // let diasRestante = dataRetorno.diasRestante;
    let quantidadeDiasUteis = dataRetorno.quantidadeDiasUteis;
    let quantidadeDomingo = dataRetorno.quantidadeDomingo;
    let quantidadeSabado = dataRetorno.quantidadeSabado;
    let diasConta = 0;

    let listaCartao = this.state.listaCartao;
    let valorMensal;
    let totalCartao;
    let valorProxPeriodo;
    let complemento;
    let economia;

    for (let i = 0; i < listaCartao.length; i++) {
      valorMensal = listaCartao[i].tagn0 * listaCartao[i].tagn1;
      totalCartao = listaCartao[i].tagn2;
      if (listaCartao[i].tagn7 === 1) {
        diasConta = quantidadeDiasUteis;
      }

      if (listaCartao[i].tagn7 === 2) {
        diasConta = quantidadeDiasUteis + quantidadeSabado
      }

      if (listaCartao[i].tagn7 === 3) {
        diasConta = quantidadeDiasUteis + quantidadeSabado + quantidadeDomingo
      }

      diasConta = diasConta + gordura;

      valorProxPeriodo = listaCartao[i].tagn1 * (listaCartao[i].tagn0 + diasConta);
      if (valorProxPeriodo === 0) {
        complemento = 0;
        economia = 0;
      } else {
        complemento = valorProxPeriodo - totalCartao;
        economia = valorMensal - complemento;
      }

      if (complemento < 0) {
        complemento = 0;
      }

      if (economia < 0) {
        economia = 0
      }
      listaCartao[i].tags9 = listaCartao[i].tags9 + '_' + diasConta
      listaCartao[i].tagn8 = economia;
      listaCartao[i].tagn9 = complemento;
      listaCartao[i].tagn10 = diasConta;

      this.pessoaService.salvar(listaCartao[i]);
    }


    this.setState({
      listaCartao: listaCartao,
      calcularEconomiaCarregando: false,
      vModalCalculo: false,
    })
    this.toast.current.show([{
      severity: 'success', summary: 'Calculo realizado', detail: 'Calculo realizado com sucesso.', sticky: false
    }])
  }

  obterDiasRestantesDoMes(pData) {
    let dataAtual = new Date(pData);
    let mesAtual = dataAtual.getMonth();
    let ultimoDiaDoMes = new Date(dataAtual.getFullYear(), mesAtual + 1, 0);
    let diasRestante = ultimoDiaDoMes.getDate() - dataAtual.getDate() + 1;
    let dataRetorno = {
      diasRestante: 0,
      quantidadeDiasUteis: 0,
      quantidadeSabado: 0,
      quantidadeDomingo: 0,
    }
    let quantidadeDomingo = 0,
      quantidadeSegunda = 0,
      quantidadeTerca = 0,
      quantidadeQuarta = 0,
      quantidadeQuinta = 0,
      quantidadeSexta = 0,
      quantidadeSabado = 0;

    for (let i = 0; i < diasRestante; i++) {
      let dia = new Date(dataAtual.getFullYear(), mesAtual, dataAtual.getDate() + i);
      if (dia.getMonth() === mesAtual) {
        switch (dia.getDay()) {
          case 0:
            quantidadeDomingo++;
            break;
          case 1:
            quantidadeSegunda++;
            break;
          case 2:
            quantidadeTerca++;
            break;
          case 3:
            quantidadeQuarta++;
            break;
          case 4:
            quantidadeQuinta++;
            break;
          case 5:
            quantidadeSexta++;
            break;
          case 6:
            quantidadeSabado++;
            break;
        }
      }
    }

    let diasUtil = quantidadeSegunda + quantidadeTerca + quantidadeQuarta + quantidadeQuinta + quantidadeSexta;
    dataRetorno.diasRestante = diasRestante;
    dataRetorno.quantidadeDiasUteis = diasUtil;
    dataRetorno.quantidadeDomingo = quantidadeDomingo;
    dataRetorno.quantidadeSabado = quantidadeSabado;
    console.log(dataRetorno)
    this.setState({ diasRestante: dataRetorno.diasRestante });
    return dataRetorno
  }

  async deletarCartao() {
    let pessoa = this.state.cartaoSelecionado;
    pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilDeletar;
    await this.pessoaService.salvar(pessoa);
    this.toast.current.show([
      { severity: 'success', summary: 'Cartão deletado', detail: `Cartão deletado com sucesso`, sticky: false },
    ]);
    this.setState({ vModalDeletarCartao: false, cartaoSelecionado: null })
    this.listar();
  }


  async gerarPedidoTxt() {
    this.mensagem.current.clear();
    let pessoaEmpresa = this.state.pessoaEmpresa;
    let listaCartao = this.state.listaCartao;
    let listaCartaoSelecionado = [];
    for (let i = 0; i < listaCartao.length; i++) {
      if (listaCartao[i].tags3 === 'S') {
        listaCartaoSelecionado.push(listaCartao[i]);
      }
    }

    if (listaCartaoSelecionado.length < 1) {
      this.toast.current.show([{ severity: 'warn', summary: 'Nenhum cartão marcado.', detail: `Marque os cartões desejados.`, sticky: false }]);
      return
    }

    let retorno = await this.util.gerarArquivoTxtRioCardMais(pessoaEmpresa.documento, listaCartaoSelecionado);
    if (retorno) {
      this.toast.current.show([{ severity: 'success', summary: 'Pedido gerado com sucesso.', detail: `Arquivo de pedido gerado com sucesso`, sticky: false }]);
      return
    }
  }
  render() {
    return (
      <div>
        {/* NAVEGAÇÃO */}
        {this.state.liberarAcesso.detalheCartao && <Navigate target="_blank" to={"/sistema/admin/detalhe_cartao?id=" + (this.state.pessoaFuncionario._seqPessoa)} />}
        {/* CALCULO DE DIAS */}
        <Dialog
          header="Calculo de dias"
          visible={this.state.vModalCalculoDias}
          style={{ width: 900 }}
          onHide={() => this.setState({ vModalCalculoDias: false })}
        >
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>

            <div>
              <label>Mês</label><br />
              <Dropdown
                onChange={pItem => this.setState({ mesSelecionado: pItem.value })}
                value={this.state.mesSelecionado}
                options={this.state.meses.meses}
                optionLabel='nome'
                placeholder='Selecione a operadora'
                filter
                filterBy="nome"
                style={{
                  width: "100%",
                  height: 40,
                }}
              />
            </div>

            <div>
              <label>Ano</label><br />
              <InputText
                onChange={(pTexto) => this.setState({ ano: pTexto.target.value })}
                value={this.state.ano || ''}
                style={{
                  height: 40,
                  width: '100%',
                }}
              />
            </div>

          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => this.calcularDias(this.state.ano, this.state.mesSelecionado)}
              label="Calcular"
              loading={this.state.calcularDiaCarregando}
            />
          </div>
        </Dialog>

        {/* INFORMATIVO */}
        <Dialog
          header="Informativo"
          visible={this.state.vModalErrorOgt}
          style={{ width: 900 }}
          onHide={() => this.setState({ vModalErrorOgt: false, mensagemErrorOgt: '' })}
        >
          <div style={{
            borderRadius: 5,
            background: '#ffe7e6',
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 20,
            alignItems: "center",
            padding: 10
          }}>
            <i className="pi pi-times-circle" style={{ 'fontSize': '2em', color: '#ff5757' }}></i>
            <p style={{ color: '#ff5757', fontSize: 20 }}>OGT: {this.state.mensagemErrorOgt}</p>
          </div>
        </Dialog>

        {/* CALCULO */}
        <Dialog
          header="Calular economia"
          onHide={() => this.setState({ vModalCalculo: false })}
          visible={this.state.vModalCalculo}
          style={{ width: 900 }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: 20 }}>
            <div>
              <label>Data base</label><br />
              <Calendar
                // onChange={(pTexto) => {
                //   this.obterDiasRestantesDoMes(pTexto.value)
                //   this.setState({ dataBase: pTexto.value })
                // }}
                disabled
                value={this?.state?.dataBase}
                showButtonBar
                style={{ width: "100%", height: 40 }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
              />
            </div>

            <div>
              <label>Dias de margem</label><br />
              <InputText
                value={this.state?.pessoaEmpresa?.tagn0 || ''}
                disabled
              />
            </div>
            <Button
              label="Calcular economia"
              disabled={this.state.calcularEconomiaCarregando}
              onClick={() => this.calcularEconomia()}
            />
          </div>
        </Dialog>

        {/* CADASTRO DE CARTOES */}
        <Dialog
          header="Cadastro de cartões"
          visible={this.state.vModalNovo}
          style={{ width: 900 }}
          onHide={() => this.resetar()}
        >

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              let inputsOkay = this.validarInputs();
              if (!inputsOkay) {
                return;
              } else {
                this.salvar();
              }
            }}
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div
              style={{
                marginTop: 5,
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: 20,
              }}
            >

              <div>
                <label>Empresa</label><br />
                <InputText
                  disabled
                  onChange={pItem => this.setState({ pessoaEmpresa: pItem.target.value })}
                  value={this.state?.pessoaEmpresa?.nome}
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: this.state.inputError.empresa && "var(--red-600)",
                  }}
                />
                {this.state.inputError.empresa && (
                  <small style={{ color: "var(--red-600)" }}>
                    Selecione uma empresa
                  </small>
                )}
              </div>

              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, nome: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.nome || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.nome && "var(--red-600)" }} />
                {this.state.inputError.nome && (
                  <small style={{ color: "var(--red-600)" }}>
                    Nome inválido
                  </small>
                )}
              </div>

              <div>
                <label>CPF</label><br />
                <InputText
                  type="tel"
                  value={this.state.pessoaFuncionario.documento || ''}
                  onChange={e => {
                    if (e.target.value.length > 14) return;
                    this.setState({
                      pessoaFuncionario: {
                        ...this.state.pessoaFuncionario,
                        documento: this.util.formatarCPF(e.target.value)
                      }
                    })
                  }}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.cpf && "var(--red-600)" }} />
                {this.state.inputError.cpf && (
                  <small style={{ color: "var(--red-600)" }}>
                    Cpf inválido
                  </small>
                )}
              </div>

              <div>
                <label>Matricula</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tags0: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tags0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.matricula && "var(--red-600)" }} />
                {this.state.inputError.matricula && (
                  <small style={{ color: "var(--red-600)" }}>
                    Matricula inválido
                  </small>
                )}
              </div>

              <div>
                <label>N° do cartão </label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tags1: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tags1 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.numeroCartao && "var(--red-600)" }} />
                {this.state.inputError.numeroCartao && (
                  <small style={{ color: "var(--red-600)" }}>
                    Numero cartão inválido
                  </small>
                )}
              </div>

              <div>
                <label>Dias trabalhados</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tagn0: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tagn0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.diasTrabalhado && "var(--red-600)" }} />
                {this.state.inputError.diasTrabalhado && (
                  <small style={{ color: "var(--red-600)" }}>
                    Dias trabalhados inválido
                  </small>
                )}
              </div>

              <div>
                <label>Dias das semanas</label><br />
                <Dropdown
                  onChange={pItem => this.setState({
                    pessoaFuncionario: {
                      ...this.state.pessoaFuncionario, tagn7: pItem.value
                    }
                  })}
                  value={this.state.pessoaFuncionario.tagn7}
                  options={[{ nome: "Segunda a Sexta", value: 1 }, { nome: "Segunda a Sábado", value: 2 }, { nome: "Segunda a Domingo", value: 3 }]}
                  optionLabel='nome'
                  placeholder='Selecione a operadora'
                  filter
                  filterBy="nome"
                  style={{
                    width: "100%",
                    height: 40,
                  }}
                />
              </div>

              <div>
                <label>Valor diário</label><br />
                <InputNumber
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tagn1: pTexto.value }
                  })}
                  value={this.state.pessoaFuncionario.tagn1 || ''}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{ width: '100%', height: 40 }}
                  inputStyle={{ width: '100%', height: 40, borderColor: this.state.inputError.valorDiario && "var(--red-600)" }} /><br />
                {this.state.inputError.valorDiario && (
                  <small style={{ color: "var(--red-600)" }}>
                    Valor diário inválido
                  </small>
                )}
              </div>

              <div>
                <label>Operadora</label><br />
                <Dropdown
                  onChange={pItem => this.setState({ pessoaOperadora: pItem.value })}
                  value={this.state.pessoaOperadora}
                  options={this.state.listaOperadoraRelacionada}
                  optionLabel='_pessoaRelacionadaNome'
                  placeholder='Selecione a operadora'
                  filter
                  filterBy="_pessoaRelacionadaNome"
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: this.state.inputError.operadora && "var(--red-600)",
                  }}
                />
                {this.state.inputError.operadora && (
                  <small style={{ color: "var(--red-600)" }}>
                    Selecione uma operadora
                  </small>
                )}
              </div>

            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                loading={this.state.salvarCarregando}
                label="Salvar"
                className="p-button-raised"
                type="submit"
              />
            </div>
          </form>

        </Dialog>

        {/* IMPORT EXCEL */}
        <Dialog
          header="Importe de excel"
          visible={this.state.vModaExcel}
          style={{ width: 900 }}
          onHide={() => this.setState({ vModaExcel: false })}>
          <label style={{ display: 'inline-block' }}>Selecione o arquivo</label>

          {this.state.salvarExcelCarregando ? <><br />
            <Button
              loading={true}
              icon="pi pi-file-excel"
              label="Importar lista do Excel"
              style={{ backgroundColor: this.constante.corPrincipal, border: 'none' }}
            /></>
            :
            <label
              htmlFor="input_file"
              style={{
                color: 'green',
                display: "flex",
                cursor: 'pointer',
                padding: 8,
                border: '1px solid #999',
                borderRadius: 5,
                width: 300
              }}
            >
              <i className="pi pi-file-excel"></i>
              <span style={{ textAlign: 'center' }}>Importar cartões do Excel</span>
            </label>
          }

          <input
            type="file" id="input_file" style={{ display: 'none' }}
            onChange={pEvento => this.gerenciarArquivoExcel(pEvento.target.files[0])}
            ref={this.inputFileRef} />

        </Dialog>

        {/* MODAL DELETAR CARTAO */}
        <Dialog
          header="Deseja realmente deletar o cartão?"
          visible={this.state.vModalDeletarCartao}
          style={{ width: 900 }}
          onHide={() => this.setState({ cartaoSelecionado: null, vModalDeletarCartao: false })}
        >
          <h4>Nome do funcionario:</h4>
          <p>{this.state?.cartaoSelecionado?.nome}</p>

          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', gap: 20 }}>
            <Button label="Sim, deletar" onClick={() => this.deletarCartao()} />
            <Button label="Não, sair" onClick={() => this.setState({ cartaoSelecionado: null, vModalDeletarCartao: false })} />
          </div>
        </Dialog>
        <HeaderAdm
          setState={(pPessoaEmpresa) => this.setState({ pessoaEmpresa: pPessoaEmpresa })}
        />
        <main style={{ width: "98%", margin: "20px auto" }}>
          <Messages ref={this.mensagem} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid lightgray",
              padding: 20,
              borderRadius: 3,
            }}
          >

            <div>
              <h1 style={{ fontSize: 22 }}>Cartões</h1>
              <h2 style={{ fontSize: 20, color: 'gray' }}>Cartões</h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Menu model={this.state.itens} popup ref={el => this.menu = el} id="popup_menu" />
              <Button loading={this.state.buscarCarregando} label="Opções" icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} aria-controls="popup_menu" aria-haspopup />
            </div>
          </div>

          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 20 }}>

              {/* FILTROS */}
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                <div>
                  <label>Nome</label><br />
                  <InputText
                    onChange={pTexto => this.setState({ filtros: { ...this.state.filtros, nome: pTexto.target.value } })}
                    value={this.state.filtros.nome || ''}
                    placeholder="Nome"
                    style={{ width: '100%', height: 40 }}
                  />
                </div>

                <div>
                  <label>CPF</label><br />
                  <InputText
                    onChange={pTexto => this.setState({
                      filtros: {
                        ...this.state.filtros, documento: this.util.formatarCPF(pTexto.target.value)
                      }
                    })}
                    placeholder="000.000.000-00"
                    value={this.state.filtros.documento || ''}
                    style={{ width: '100%', height: 40 }}
                  />
                </div>

                <div>
                  <label>Matricula</label><br />
                  <InputText
                    onChange={pTexto => this.setState({
                      filtros: {
                        ...this.state.filtros, matricula: pTexto.target.value
                      }
                    })}
                    placeholder="Matricula"
                    value={this.state.filtros.matricula || ''}
                    style={{ width: '100%', height: 40 }}
                  />
                </div>
              </div>


              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                <div>
                  <label>Data cadastro inicial</label><br />
                  <Calendar
                    onChange={(pTexto) =>
                      this.setState({
                        filtros: {
                          ...this.state.filtros,
                          dataCadastro_inicial: pTexto.target.value,
                        },
                      })
                    }
                    showButtonBar
                    style={{ width: "100%", height: 40 }}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                  />
                </div>

                <div>
                  <label>Data cadastro final</label><br />
                  <Calendar
                    onChange={(pTexto) =>
                      this.setState({
                        filtros: {
                          ...this.state.filtros,
                          dataCadastro_final: pTexto.target.value,
                        },
                      })
                    }
                    showButtonBar
                    style={{ width: "100%", height: 40 }}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                  />
                </div>

                <div>
                  <label>Data ultima consulta inicial</label><br />
                  <Calendar
                    onChange={(pTexto) =>
                      this.setState({
                        filtros: {
                          ...this.state.filtros,
                          dataAtualizacao_inicial: pTexto.target.value,
                        },
                      })
                    }
                    showButtonBar
                    style={{ width: "100%", height: 40 }}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                  />
                </div>

                <div>
                  <label>Data ultima consulta final</label><br />
                  <Calendar
                    onChange={(pTexto) =>
                      this.setState({
                        filtros: {
                          ...this.state.filtros,
                          dataAtualizacao_final: pTexto.target.value,
                        },
                      })
                    }
                    showButtonBar
                    style={{ width: "100%", height: 40 }}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                  />
                </div>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                <div>
                  <label>Status busca de saldo</label><br />
                  <Dropdown
                    onChange={pItem => this.setState({
                      filtros: {
                        ...this.state.filtros, status: pItem.value
                      }
                    })}
                    value={this.state.filtros.status}
                    options={[{ nome: "Todos", value: "" }, { nome: "Atualizado", value: "1" }, { nome: "Aguardando", value: "2" }]}
                    optionLabel='nome'
                    optionValue="value"
                    placeholder='Selecione'
                    style={{
                      width: "100%",
                      height: 40,
                    }}
                  />
                </div>

                <div>
                  <label>Inconsistência do registro</label><br />
                  <Dropdown
                    onChange={pItem => this.setState({
                      filtros: {
                        ...this.state.filtros, inconsistencia: pItem.value
                      }
                    })}
                    value={this.state.filtros.inconsistencia}
                    options={[{ nome: "Todos", value: "" }, { nome: "sim", value: "S" }, { nome: "não", value: "N" }]}
                    optionLabel='nome'
                    optionValue="value"
                    placeholder='Selecione'
                    style={{
                      width: "100%",
                      height: 40,
                    }}
                  />
                </div>

                <div>
                  <label>Selecionado</label><br />
                  <Dropdown
                    onChange={pItem => this.setState({
                      filtros: {
                        ...this.state.filtros, marcado: pItem.value
                      }
                    })}
                    value={this.state.filtros.marcado}
                    options={[{ nome: "Todos", value: "" }, { nome: "Sim", value: "S" }, { nome: "Não", value: "N" }]}
                    optionLabel='nome'
                    optionValue="value"
                    placeholder='Selecione'
                    style={{
                      width: "100%",
                      height: 40,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', width: "100%", marginTop: 20, justifyContent: "flex-end", gap: 30 }}>

              <Button loading={this.state.salvarCarregando} label="Limpar filtros" className="p-button-raised"
                onClick={() => this.setState({
                  filtros: {
                    ...this.state.filtros, nome: '',
                    dataCadastro_inicial: '',
                    dataCadastro_final: '',
                    dataAtualizacao_inicial: '',
                    dataAtualizacao_final: '',
                    seqPerfil: '',
                    documento: '',
                    pessoaEmpresa: null,
                    status: '',
                    inconsistencia: '',
                    marcado: '',
                    matricula: '',
                  }
                })}
              />


              <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised"
                onClick={() => this.listar()}
              />


            </div>
          </div>

          <div
            style={{
              display: "flex",
              gridTemplateColumns: "1fr",
              marginTop: 20,
              gap: 10,
            }}
          >
            <DataTable
              showGridlines
              removableSort
              paginator
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
              rows={5}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              style={{ width: "100%" }}
              value={this.state.listaCartao}
              onSelectionChange={e => this.selecionar(e.value)}
              loading={this.state.tabelaCarregando}
              header={() => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 >Cartões</h2>
                <div style={{ display: 'flex', gap: 20 }}>

                  <Button
                    label="Marcar todos"
                    type="button"
                    loading={this.state.marcarTodosCarregando}
                    onClick={() => this.marcarTodos()}
                  />
                  <Button
                    label="Desmarcar todos"
                    loading={this.state.desmarcarTodosCarregando}
                    type="button"
                    onClick={() => this.desmarcarTodos()}
                  />
                  <Button
                    label="Excel"
                    type="button"
                    icon="pi pi-file-excel"
                    className="p-button-success mr-2"
                    data-pr-tooltip="XLS"
                    onClick={() => this.exportExcel()} />
                </div>

              </div>}
            >
              {/* <Column
                header="_seqPessoa"
                field="_seqPessoa"
                sortable />
              <Column
                header="_seqTipoRelacionamento"
                field="_seqTipoRelacionamento"
                sortable /> */}
              <Column
                header="Marcados"
                field="tags3"
                sortable
                body={(pItem) => <Checkbox onChange={() => this.marcarCartao(pItem)} checked={pItem.tags3 === 'S' ? true : false} />} />

              <Column header='Nome'
                field='nome'
                sortable
                body={(pItem) => <Link
                  style={{ color: this.constante.azul }}
                  target="_blank" to={"/sistema/admin/detalhe_cartao?id=" + pItem._seqPessoa}>{pItem.nome}</Link>} />

              <Column header='CPF'
                field='documento'
                sortable />

              <Column header='Matricula'
                field='tags0'
                sortable />

              <Column header='N. Cartão'
                field='tags1'
                sortable />

              <Column header='Operadora'
                field='tags2'
                sortable />

              <Column header='Dias da semana'
                field='tagn7'
                sortable
                body={(item) => <p>{item.tagn7 === 1 ? 'Segunda a Sexta' : item.tagn7 === 2 ? 'Segunda a Sábado' : item.tagn7 === 3 ? 'Segunda a Domingo' : ''}</p>}
              />

              <Column header='Saldo recarga'
                field='tagn6'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn6)}</p>} />

              <Column header='Saldo uso'
                field='tagn5'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn5)}</p>} />

              <Column header='Saldo total'
                field='tagn2'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn2)}</p>} />

              <Column header='Dias trabalhados'
                field='tagn0'
                sortable />

              <Column header='Valor diario'
                field='tagn1'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn1)}</p>} />

              <Column header='Valor mensal'
                field='tagn2'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn0 * item.tagn1)}</p>} />

              <Column header='Dias restante'
                field='tagn10'
                sortable />

              <Column header='Economia'
                field='tagn8'
                sortable
                body={pTexto => <p>{this.util.formatarValor(pTexto.tagn8)}</p>}
              />

              <Column header='Complemento'
                field='tagn9'
                sortable
                body={pTexto => <p>{this.util.formatarValor(pTexto.tagn9)}</p>}
              />

              <Column header='Data atualização do saldo'
                sortable
                body={item => <p>{this.util.formatarDataComTimestamp(item.tagd0)}</p>} />

              <Column header='Hora atualização do saldo'
                sortable
                body={item => <p>{this.util.formatarHora(item.tags7)}</p>} />

              <Column header='Data consulta'
                sortable
                body={item => <p>{this.util.formatarDataComTimestamp(item.tagd1)}</p>} />

              <Column header='Hora consulta'
                sortable
                body={item => <p>{this.util.formatarHora(item.tags8)}</p>} />

              <Column header='Data cadastro'
                sortable
                body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />

              <Column header='Status busca de saldo'
                sortable
                body={(pItem) => <p style={{ textAlign: 'center', color: pItem.tags9 === '2' ? 'orange' : 'green' }}
                >{pItem.tags9 === '2' ? 'Aguardando' : 'Atualizado'}</p>} />

              <Column header='Inconsistencia'
                field="tags4"
                sortable
              />
              <Column
                header='Empresa'
                field='_pessoaRelacionadaNome'
                sortable
              />
              <Column header='Deletar'
                sortable
                body={(pItem) => <Button
                  icon="pi pi-trash"
                  onClick={() => this.setState({ cartaoSelecionado: pItem, vModalDeletarCartao: true })} />}
              />
            </DataTable>
          </div>
        </main>
        <Toast ref={this.toast} />
      </div >
    );
  }
}
