export default class Constante {
  // ****************** DESENVOLVIMENTO ******************//
  // url_base = "http://192.168.1.20:8080/v1/api/";
  // seqTipoAtendimentoRequisicaoOGT = "1845c8bf-a54e-4965-999c-9f84541676c2";
  // seqPessoaSuperAdmin = 'ff32dbaa-bd1a-4c36-b0f8-d79b66f5301b';

  //************* PRODUÇÃO ******************//
  url_base = "https://api.lirida.com.br/v1/api/";
  seqTipoAtendimentoRequisicaoOGT = "75c9734f-3089-49ff-8dca-21310fe3d129";
  seqPessoaSuperAdmin = 'a6e6c2ef-69a0-4413-981b-adb65b18640a';

  token = "f4ffc9a9-61ff-4377-b1d1-a8c65fbf290e";

  //OGT
  url_base_ogt = 'https://teste.lirida.com.br/api/maxximus';
  // url_base_ogt = 'http://192.168.1.14:8080/api/maxximus';

  cdEmpresa = 34;
  idArquivo = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  cpfOgt = "825.405.607-25";
  senhaOGt = "m@nvvslm8";
  // LOGIN = TAURUS01 SENHA = Ts@@2023

  // CORES
  azul = "#0069d9";
  verde = "lightgreen"

  //USUARIO
  seqUsuario = "afc76f81-529e-4cc0-82d3-43143aea392d";

  //PERFIL
  seqPerfilSuperAdministrador = "fbd705ea-7ab7-4d78-9ba5-e8c95474949a";
  seqPerfilAdministrador = "3febc9ab-5c06-4c00-82c9-598180dca9ae";
  seqPerfilEmpresa = "4fcaeeeb-3f76-47e2-9792-a7d61700ab94";
  seqPerfilAfiliado = "6dd99e03-c3d8-4771-9182-4a893d39d421";
  seqPerfilOperadora = "61c9ec58-60d0-4bf6-8d92-394d70dfe321";
  seqPerfilFuncionarioOK = "0b40218a-77f5-4ebf-b5f4-c8ee1de990ef";
  seqPerfilFuncionarioPendente = "8946eb92-5c13-4224-bec2-25f30373036c";
  seqPessoaPerfilDeletar = '8d694484-2d02-4df2-a47f-0e001cd57b73';


  //CONTA BANCARIA
  seqContaBancaria = "3223d3a6-d1f7-451b-b86f-bbfc7b545498";

  // TIPO DOCUMENTO
  seqTipoDocumento = "9b595c78-816f-4397-bb0a-7b8f7d94b4e3";

  //Email
  seqEmailCadastro = "62c44534-40a1-4c6a-8b5e-bbadc887d4c8";

  //Relacionamento
  seqTipoRelacionamentoEmpresaCartao = "105f0959-7855-4c5a-b2b9-21cf65ab36dd";
  seqTipoRelacionamentoOperadoraParceira = "55058191-d674-4331-882a-6301a3ebe6b9";
  seqTipoRelacionamentoDeletado = '5600cbfd-4b4a-464e-b4a0-529b84760b55';

  corAzul = 'rgb(0, 105, 217)';

}