import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//css
import "./index.css";

// Primereact
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { locale, addLocale } from "primereact/api";

//paginas
//adm
import AdmDocumento from './Pages/Administrativo/AdmDocumento';
import Empresa from './Pages/Administrativo/Empresa';
import PerfilAdm from './Pages/Administrativo/Perfil';
import ContaPagar from './Pages/Administrativo/ContaPagar';
import ContaReceber from './Pages/Administrativo/ContaReceber';
import Operadora from './Pages/Administrativo/Operadora';
import DetalheCartão from './Pages/Administrativo/DetalheCartao';
import DetalheEmpresa from './Pages/Administrativo/DetalheEmpresa';
import Cartao from './Pages/Administrativo/Cartao';
import DetalheOperadora from './Pages/Administrativo/DetalheOperadora';
import Ogt from './Pages/Administrativo/Ogt';


import Login from './Pages/Autenticacao/Login';
import HeaderAutenticacao from './Pages/Autenticacao/Components/HeaderAutenticacao';
import RecuperarSenha from './Pages/Autenticacao/RecuperarSenha';
import Cadastro from './Pages/Autenticacao/Cadastro';

import EmpresaPerfil from './Pages/Empresas/EmpresaPerfil';
import EmpresaCartao from './Pages/Empresas/EmpresaCartao';
import EmpresaDetalheCartao from './Pages/Empresas/EmpresaDetalheCartao';
import EmpresaDocumento from './Pages/Empresas/EmpresaDocumento';
import Consulta from './Pages/Empresas/Consulta';



/*Alterando lingua de en pra pt calendario*/
addLocale('br', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  today: 'Hoje',
  clear: 'Limpar'
});

locale('br');


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<BrowserRouter>

  <Routes>
    <Route path="/*" element={<>
      <HeaderAutenticacao />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/recuperar_senha' element={<RecuperarSenha />} />
        <Route path='/cadastro' element={<Cadastro />} />
      </Routes>
    </>
    } />

    <Route path="/sistema/admin/*" element={
      <Routes>
        <Route path='/pedido' element={<AdmDocumento />} />
        <Route path='/empresa' element={<Empresa />} />
        <Route path='/financeiro/conta_pagar' element={<ContaPagar />} />
        <Route path='/financeiro/conta_receber' element={<ContaReceber />} />
        <Route path='/detalhe_empresa' element={<DetalheEmpresa />} />
        <Route path='/perfil' element={<PerfilAdm />} />
        <Route path='/cartao' element={<Cartao />} />
        <Route path='/detalhe_cartao' element={<DetalheCartão />} />
        <Route path='/operadora' element={<Operadora />} />
        <Route path='/ogt' element={<Ogt />} />
        <Route path='/detalhe_operadora' element={<DetalheOperadora />} />
      </Routes>
    } />

    <Route path="/sistema/empresa/*" element={
      <Routes>
        <Route path='/pedido' element={<EmpresaDocumento />} />
        <Route path='/cartao' element={<EmpresaCartao />} />
        <Route path='/consulta' element={<Consulta />} />
        <Route path='/detalhe_cartao' element={<EmpresaDetalheCartao />} />
        <Route path='/perfil' element={<EmpresaPerfil />} />
      </Routes>
    } />
  </Routes>
</BrowserRouter>);