import { Component } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import HeaderEmpresa from "./Components/HeaderEmpresa";
import FormFotoPerfil from "../Components/FormFotoPerfil";
import FormDadosPessoais from "../Components/FormDadosPessoais";
import FormEndereco from "../Components/FormEndereco";
import FormAlterarSenha from "../Components/FormAlterarSenha";

export default class EmpresaPerfil extends Component {
  util = new Util();
  constante = new Constante();

  state = {
    pessoaUsuario: new Pessoa(),
  };

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    this.setState({ pessoaUsuario: pessoaUsuario });
  }

  render() {
    return (
      <div>
        <HeaderEmpresa />

        <main
          style={{
            maxWidth: "90%",
            width: 700,
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
            padding: "20px 0 200px 0",
          }}
        >
          <FormFotoPerfil />

          <TabView style={{ width: 700, margin: "0 auto" }}>
            <TabPanel header="Dados pessoais" style={{ width: "100%" }}>
              <FormDadosPessoais />
            </TabPanel>
            <TabPanel header="Endereço" style={{ width: "100%" }}>
              <FormEndereco />
            </TabPanel>
            <TabPanel header="Alterar senha" style={{ width: "100%" }}>
              <FormAlterarSenha />
            </TabPanel>
          </TabView>
        </main>
      </div>
    );
  }
}
