import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Button } from "primereact/button";
import { Component } from "react";
import Constante from "../../../Util/Constante";
import Util from "../../../Util/Util";
import logo from '../../../Assets/Imagens/logo.png';

export default class HeaderEmpresa extends Component {
    constante = new Constante();
    util = new Util();

    state = {
        pessoaUsuario: new Pessoa(),
        dropdownItemVisivel: false,
        dropdownFinanceiroVisivel: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.setState({ pessoaUsuario: pessoaUsuario });
    }

    render() {
        return <header style={{ height: 50, backgroundColor: this.constante.azul, padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img alt='Maxximus' src={logo} style={{ width: 150, height: 50, objectFit: "contain" }} />

            <nav style={{ display: 'flex', alignItems: "center" }}>

                <Button
                    label="Cartões"
                    onClick={() => window.location.href = "/sistema/empresa/cartao"}
                    className="p-button-link"
                    style={{ color: 'white' }} />

                <Button
                    label="Consulta"
                    onClick={() => window.location.href = "/sistema/empresa/consulta"}
                    className="p-button-link"
                    style={{ color: 'white' }} />

                {/* <Button
                    label="Pedidos"
                    onClick={() => window.location.href = "/sistema/empresa/pedido"}
                    className="p-button-link"
                    style={{ color: 'white' }} /> */}

                <Button
                    label="Perfil"
                    onClick={() => window.location.href = "/sistema/empresa/perfil"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Sair"
                    onClick={() => {
                        this.util.removerLocalStorage("PESSOA_USUARIO");
                        window.location.href = "/";
                    }}
                    className="p-button-link"
                    style={{ color: 'white' }} />
            </nav>
        </header>
    }
}