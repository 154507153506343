import React, { Component, createRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { InputNumber } from "primereact/inputnumber";
import HeaderEmpresa from "./Components/HeaderEmpresa";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Checkbox } from "primereact/checkbox";

export default class EmpresaCartao extends Component {
  constante = new Constante();
  mensagem = createRef();
  toast = createRef();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaCartao: new Pessoa(),
    pessoaOperadora: new PessoaRelacionamento(),
    cartaoSelecionado: null,
    listaCartao: [],
    listaOperadora: [],
    vModalNovo: false,
    vModalDeletarCartao: false,
    tabelaCarregando: false,
    salvarCarregando: false,
    marcarDesmarcarCarregando: false,
    inputError: {
      nome: false,
      cpf: false,
      matricula: false,
      numeroCartao: false,
      empresa: false,
      arquivo: false,
      operadora: false,
      loginOperadora: false,
      senhaOperadora: false,
      diasTrabalhado: false,
      valorDiario: false,
    },

    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
      inconsistencia: '',
      documento: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaCartao = await this.listar(pessoaUsuario);
    let listaOperadora = await this.listarOperadora(pessoaUsuario);
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaCartao: listaCartao,
      listaOperadora: listaOperadora,
    })
  }

  async salvar() {
    this.setState({ salvarCarregando: true })
    this.mensagem.current.clear();
    let pessoaUsuario = this.state.pessoaUsuario;
    let pessoaCartao = this.state.pessoaCartao;

    let listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresaCartao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.documento';
    pesquisa.conteudo = pessoaCartao.documento;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retornoPesquisa = await this.pessoaService.listarComFiltro(listaPesquisa);
    let pessoa = retornoPesquisa.objeto[0];
    console.log(pessoa)
    if (pessoa?.documento === pessoaCartao.documento) {
      this.mensagem.current.show([
        {
          severity: "error",
          summary: "Essa pessoa já possuí cadastro",
          sticky: true,
        },
      ]);
      this.resetar();
      return
    }

    pessoaCartao.seqUsuario = this.constante.seqUsuario;
    pessoaCartao.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;
    pessoaCartao.tipoDocumento = "CPF";
    pessoaCartao.situacao = "ATIVO";
    pessoaCartao.tags2 = this.state.pessoaOperadora._pessoaRelacionadaNome;
    pessoaCartao.tags3 = 'N';
    pessoaCartao.tagn3 = this.state.pessoaOperadora.tagn0;
    pessoaCartao.tags5 = this.state.pessoaOperadora.tags0;
    pessoaCartao.tags6 = this.state.pessoaOperadora.tags1;
    pessoaCartao.tagn0 = Number(pessoaCartao.tagn0);
    let retorno = await this.pessoaService.salvar(pessoaCartao);
    pessoaCartao = retorno.objeto;

    let pessoaRelacionamento = new PessoaRelacionamento();
    pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
    pessoaRelacionamento.seqPessoa = pessoaCartao._seqPessoa;
    pessoaRelacionamento.seqPessoaRelacionada = this.state.pessoaUsuario._seqPessoa;
    pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEmpresaCartao;
    retorno = await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);
    console.log(retorno);

    this.listar()

    this.resetar();
    this.mensagem.current.show([
      {
        severity: "success",
        summary: "Cartão cadastrado com sucesso.",
        sticky: true,
      },
    ]);
  }

  resetar() {
    let inputError = this.state.inputError;
    let listaKey = Object.keys(inputError);
    listaKey.forEach(key => inputError[key] = false);
    this.setState({
      vModalNovo: false,
      inputError: inputError,
      salvarCarregando: false,
      pessoaCartao: new Pessoa(),
      pessoaOperadora: new PessoaRelacionamento(),
    })
  }

  validarInputs() {
    let inputError = this.state.inputError;
    console.log(inputError);
    let listaKey = Object.keys(inputError);
    let inputOk = true;
    listaKey.forEach(key => inputError[key] = false);

    if (!this.state.pessoaCartao.nome) {
      inputError.nome = true
    }

    if (!this.state.pessoaCartao.documento) {
      inputError.cpf = true
    }

    if (this.state.pessoaCartao.documento && this.state.pessoaCartao.documento < 14) {
      inputError.cpf = true
    }

    if (!this.state.pessoaCartao.tags0) {
      inputError.matricula = true;
    }


    if (!this.state.pessoaCartao.tags1) {
      inputError.numeroCartao = true;
    }


    if (!this.state.pessoaOperadora._seqPessoaRelacionamento) {
      inputError.operadora = true;
    }

    if (!this.state.pessoaCartao.tagn0) {
      inputError.diasTrabalhado = true;
    }

    if (!this.state.pessoaCartao.tagn1) {
      inputError.valorDiario = true;
    }

    listaKey.forEach(key => {
      if (inputError[key]) {
        inputOk = false
      }
    })

    this.setState({ inputError: inputError })
    return inputOk
  }

  async listar(pPessoaUsuario) {
    this.setState({ tabelaCarregando: true });
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario;
    }
    const listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresaCartao;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = "pessoa.seq_pessoa_perfil";
    pesquisa.conteudo = this.constante.seqPerfilFuncionarioOK;
    pesquisa.operacao = "AND";
    pesquisa.tipo = "TEXTO";
    listaPesquisa.push(pesquisa);


    if (this.state.filtro.inconsistencia) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.seq_pessoa_perfil';
      pesquisa.conteudo = this.state.filtro.inconsistencia;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.documento) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.documento';
      pesquisa.conteudo = this.state.filtro.documento;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.nome';
      pesquisa.conteudo = this.state.filtro.nome;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastroInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastroFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPesquisa.push(pesquisa);
    }
    console.log(listaPesquisa)
    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaCartao = retorno.objeto;


    console.log(listaCartao)
    this.setState({
      listaCartao: listaCartao,
      tabelaCarregando: false,
    })

    return listaCartao;
  }

  async listarOperadora(pPessoaUsuario) {
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario;
    }

    let listaRelacionamento = []
    let listaOperadora = []

    let retorno = await this.pessoaService.listarRelacionamento(pessoaUsuario)
    listaRelacionamento = retorno.objeto
    console.log("lista de relacionamento operadora", listaRelacionamento);

    for (let i = 0; i < listaRelacionamento.length; i++) {
      if (listaRelacionamento[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoOperadoraParceira) {
        listaOperadora.push(listaRelacionamento[i])
      }
    }

    this.setState({ listaOperadora: listaOperadora })
    return listaOperadora;
  }

  async marcarCartao(pItem) {
    let pessoaCartao = pItem;
    let listaCartao = this.state.listaCartao;
    console.log(pItem);
    if (pessoaCartao.tags3 === 'S') {
      pessoaCartao.tags3 = 'N';
    } else {
      pessoaCartao.tags3 = 'S';
    }
    let retorno = await this.pessoaService.salvar(pessoaCartao);
    for (const cartao of listaCartao) {
      if (cartao._seqPessoa === pessoaCartao._seqPessoa) {
        cartao.tags3 = pessoaCartao.tags3;
      }
    }
    this.setState({
      listaCartao: listaCartao
    })
  }

  async marcarTodos() {
    let listaCartao = this.state.listaCartao;
    this.setState({ marcarDesmarcarCarregando: true })
    for (const cartao of listaCartao) {
      cartao.tags3 = 'S';
      await this.pessoaService.salvar(cartao);
    }
    this.setState({ listaCartao: listaCartao, marcarDesmarcarCarregando: false })
  }

  async desmarcarTodos() {
    let listaCartao = this.state.listaCartao;
    this.setState({ marcarDesmarcarCarregando: true });
    for (const cartao of listaCartao) {
      cartao.tags3 = 'N';
      await this.pessoaService.salvar(cartao);
    }
    this.setState({ listaCartao: listaCartao, marcarDesmarcarCarregando: false, })
  }

  async gerarPedidoTxt() {
    this.mensagem.current.clear();
    let pessoaUsuario = this.state.pessoaUsuario;
    let listaCartao = this.state.listaCartao;
    let listaCartaoSelecionado = [];
    for (let i = 0; i < listaCartao.length; i++) {
      if (listaCartao[i].tags3 === 'S') {
        listaCartaoSelecionado.push(listaCartao[i]);
      }
    }

    if (listaCartaoSelecionado.length < 1) {
      this.mensagem.current.show([
        {
          severity: "warn",
          summary: "Nenhum cartão marcado. Marque os cartões desejados.",
          sticky: false,
        },
      ]);
      return
    }

    let arquivo = await this.util.gerarArquivoTxtRioCardMais(pessoaUsuario.documento, listaCartaoSelecionado);
    if (arquivo) {
      this.mensagem.current.show([
        {
          severity: "success",
          summary: "Seu pedido foi emitido com sucesso.",
          sticky: false,
        },
      ]);
      return
    }


  }

  async deletarCartao() {
    let pessoa = this.state.cartaoSelecionado;
    pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilDeletar;
    await this.pessoaService.salvar(pessoa);
    this.toast.current.show([
      { severity: 'success', summary: 'Cartão deletado', detail: `Cartão deletado com sucesso`, sticky: false },
    ]);
    this.setState({ vModalDeletarCartao: false, cartaoSelecionado: null })
    this.listar();
  }

  render() {
    return (
      <div>
        <Toast ref={this.toast} />
        {/* CADASTRO DE CARTÃO (PESSOA)*/}
        <Dialog
          header="Cadastro de cartões"
          visible={this.state.vModalNovo}
          style={{ width: 900 }}
          onHide={() => this.resetar()}
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              let inputsOkay = this.validarInputs();
              if (!inputsOkay) {
                return;
              } else {
                this.salvar();
              }
            }}
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div
              style={{
                marginTop: 5,
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: 20,
              }}
            >
              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaCartao:
                      { ...this.state.pessoaCartao, nome: pTexto.target.value }
                  })}
                  value={this.state.pessoaCartao.nome || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.nome && "var(--red-600)" }} />
                {this.state.inputError.nome && (
                  <small style={{ color: "var(--red-600)" }}>
                    Nome inválido
                  </small>
                )}
              </div>

              <div>
                <label>CPF</label><br />
                <InputText
                  type="tel"
                  value={this.state.pessoaCartao.documento || ''}
                  onChange={e => {
                    if (e.target.value.length > 14) return;
                    this.setState({
                      pessoaCartao: {
                        ...this.state.pessoaCartao,
                        documento: this.util.formatarCPF(e.target.value)
                      }
                    })
                  }}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.cpf && "var(--red-600)" }} />
                {this.state.inputError.cpf && (
                  <small style={{ color: "var(--red-600)" }}>
                    Cpf inválido
                  </small>
                )}
              </div>

              <div>
                <label>Matricula</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaCartao:
                      { ...this.state.pessoaCartao, tags0: pTexto.target.value }
                  })}
                  value={this.state.pessoaCartao.tags0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.matricula && "var(--red-600)" }} />
                {this.state.inputError.matricula && (
                  <small style={{ color: "var(--red-600)" }}>
                    Matricula inválido
                  </small>
                )}
              </div>

              <div>
                <label>N° do cartão </label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaCartao:
                      { ...this.state.pessoaCartao, tags1: pTexto.target.value }
                  })}
                  value={this.state.pessoaCartao.tags1 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.numeroCartao && "var(--red-600)" }} />
                {this.state.inputError.numeroCartao && (
                  <small style={{ color: "var(--red-600)" }}>
                    Numero cartão inválido
                  </small>
                )}
              </div>

              <div>
                <label>Dias trabalhados</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaCartao:
                      { ...this.state.pessoaCartao, tagn0: pTexto.target.value }
                  })}
                  value={this.state.pessoaCartao.tagn0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.diasTrabalhado && "var(--red-600)" }} />
                {this.state.inputError.diasTrabalhado && (
                  <small style={{ color: "var(--red-600)" }}>
                    Dias trabalhados inválido
                  </small>
                )}
              </div>

              <div>
                <label>Dias das semanas</label><br />
                <Dropdown
                  onChange={pItem => this.setState({
                    pessoaCartao: {
                      ...this.state.pessoaCartao, tagn7: pItem.value
                    }
                  })}
                  value={this.state.pessoaCartao.tagn7}
                  options={[{ nome: "Segunda a Sexta", value: 1 }, { nome: "Segunda a Sábado", value: 2 }, { nome: "Segunda a Domingo", value: 3 }]}
                  optionLabel='nome'
                  placeholder='Selecione a operadora'
                  filter
                  filterBy="nome"
                  style={{
                    width: "100%",
                    height: 40,
                  }}
                />
              </div>

              <div>
                <label>Valor diário</label><br />
                <InputNumber
                  onChange={pTexto => this.setState({
                    pessoaCartao:
                      { ...this.state.pessoaCartao, tagn1: pTexto.value }
                  })}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  value={this.state.pessoaCartao.tagn1 || ''}
                  style={{ width: '100%' }}
                  inputStyle={{ width: '100%', height: 40, borderColor: this.state.inputError.valorDiario && "var(--red-600)" }} /><br />
                {this.state.inputError.valorDiario && (
                  <small style={{ color: "var(--red-600)" }}>
                    Valor diário inválido
                  </small>
                )}
              </div>

              <div>
                <label>Operadora</label><br />
                <Dropdown
                  onChange={pItem => this.setState({ pessoaOperadora: pItem.target.value })}
                  value={this.state.pessoaOperadora}
                  options={this.state.listaOperadora}
                  optionLabel='_pessoaRelacionadaNome'
                  placeholder='Selecione a operadora'
                  filter
                  filterBy="_pessoaRelacionadaNome"
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: this.state.inputError.operadora && "var(--red-600)",
                  }}
                />
                {this.state.inputError.operadora && (
                  <small style={{ color: "var(--red-600)" }}>
                    Operadora inválida
                  </small>
                )}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                loading={this.state.salvarCarregando}
                label="Salvar"
                className="p-button-raised"
                type="submit"
              />
            </div>
          </form>
        </Dialog>

        {/* MODAL DELETAR CARTAO */}
        <Dialog
          header="Deseja realmente deletar o cartão?"
          visible={this.state.vModalDeletarCartao}
          style={{ width: 900 }}
          onHide={() => this.setState({ cartaoSelecionado: null, vModalDeletarCartao: false })}
        >
          <h4>Nome do funcionario:</h4>
          <p>{this.state?.cartaoSelecionado?.nome}</p>

          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', gap: 20 }}>
            <Button label="Sim, deletar" onClick={() => this.deletarCartao()} />
            <Button label="Não, sair" onClick={() => this.setState({ cartaoSelecionado: null, vModalDeletarCartao: false })} />
          </div>
        </Dialog>
        <HeaderEmpresa />
        <main style={{ width: "98%", margin: "20px auto" }}>
          <Messages ref={this.mensagem} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid lightgray",
              padding: 20,
              borderRadius: 3,
            }}
          >
            <h1 style={{ fontSize: 22 }}>Cartões</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20 }}>
              <Button
                label="Novo cartão"
                className="p-button-raised"
                onClick={() => this.setState({ vModalNovo: true })} />

              <Button
                label="Realizar pedido"
                className="p-button-raised"
                onClick={() => this.gerarPedidoTxt()} />
            </div>

          </div>

          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr ', gap: 20 }}>
              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pTexto.target.value
                    }
                  })}
                  placeholder="Nome"
                  value={this.state.filtro.nome}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>CPF</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, documento: this.util.formatarCPF(pTexto.target.value)
                    }
                  })}
                  placeholder="000.000.000-00"
                  value={this.state.filtro.documento || ''}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>Data Cadastro Inicial</label><br />
                <Calendar
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  onChange={(pTexto) => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pTexto.target.value
                    }
                  })}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>Data Cadastro Final</label><br />
                <Calendar
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  onChange={(pTexto) => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pTexto.target.value
                    }
                  })}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              {/* <div>
                <label>Inconsistência</label><br />
                <Dropdown
                  onChange={pItem => this.setState({
                    filtro: {
                      ...this.state.filtro, inconsistencia: pItem.target.value
                    }
                  })}
                  options={[
                    { nome: 'Todos', value: '' },
                    { nome: 'Sim', value: this.constante.seqPerfilFuncionarioPendente },
                    { nome: 'Não', value: this.constante.seqPerfilFuncionarioOK }]}
                  optionLabel='nome'
                  value={this.state.filtro.inconsistencia}
                  placeholder='Selecione'
                  style={{
                    width: "100%",
                    height: 40,
                  }}
                />
              </div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
              <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gridTemplateColumns: "1fr",
              marginTop: 20,
              gap: 10,
            }}
          >
            <DataTable
              showGridlines
              removableSort
              paginator
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              style={{ width: "100%" }}
              value={this.state.listaCartao}
              loading={this.state.tabelaCarregando}
              header={() => <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', gap: 20 }}>
                  <Button
                    label="Marcar todos"
                    type="button"
                    loading={this.state.marcarDesmarcarCarregando}
                    onClick={() => this.marcarTodos()}
                  />
                  <Button
                    label="Desmarcar todos"
                    loading={this.state.marcarDesmarcarCarregando}
                    type="button"
                    onClick={() => this.desmarcarTodos()}
                  />
                </div>
              </div>}
            >
              <Column
                header="Marcados"
                field="tags3"
                sortable
                body={(pItem) => <Checkbox onChange={() => this.marcarCartao(pItem)} checked={pItem.tags3 === 'S' ? true : false} />} />

              <Column header='Matricula' field='tags0' sortable />

              <Column header='Nome' field='nome' sortable body={pPessoa => <a href={"/sistema/empresa/detalhe_cartao?id=" + pPessoa._seqPessoa}>{pPessoa.nome}</a>} />

              <Column header='CPF' field='documento' sortable />

              <Column header='N. Cartão' field='tags1' sortable />

              <Column header='Valor mensal'
                field='tagn2'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn0 * item.tagn1)}</p>} />

              <Column header='Saldo total'
                field='tagn2'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn2)}</p>} />

              <Column header='Complemento'
                field='tagn9'
                sortable
                body={pTexto => <p>{this.util.formatarValor(pTexto.tagn9)}</p>}
              />

              <Column header='Economia'
                field='tagn8'
                sortable
                body={pTexto => <p>{this.util.formatarValor(pTexto.tagn8)}</p>}
              />


              <Column header='Operadora' field='tags2' sortable />

              <Column header='Dias da semana'
                field='tagn7'
                sortable
                body={(item) => <p>{item.tagn7 === 1 ? 'Segunda a Sexta' : item.tagn7 === 2 ? 'Segunda a Sábado' : item.tagn7 === 3 ? 'Segunda a Domingo' : ''}</p>}
              />

              {/* <Column header='Valor diario'
                field='tagn1'
                sortable
                body={item => <p>{this.util.formatarValor(item.tagn1)}</p>} /> */}

              {/* <Column header='Dias trabalhados' field='tagn0' sortable /> */}

              <Column header='Data cadastro' sortable body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />

              {/* <Column header='Dias restante'
                field='tags9'
                sortable
                body={pCartao => <p>{pCartao.tags9?.split('_')[1]}</p>}
              /> */}

              <Column header='Deletar'
                sortable
                body={(pItem) => <Button
                  icon="pi pi-trash"
                  onClick={() => this.setState({ cartaoSelecionado: pItem, vModalDeletarCartao: true })} />}
              />
            </DataTable>
          </div>
        </main>
      </div>
    );
  }
}
