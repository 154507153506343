import { Component, createRef } from "react";
import { Toast } from "primereact/toast";
import HeaderEmpresa from "./Components/HeaderEmpresa";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { InputText } from "primereact/inputtext";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { OgtService } from "../../Service/OgtService";
import { PedidoItemOgt } from "../../Service/PedidoItemOgt";
import { PedidoOgt } from "../../Service/PedidoOgt";
import PessoaAtendimento from "lirida-back-service/Servicos/PessoaAtendimento/PessoaAtendimento";

export default class Consulta extends Component {
  toast = createRef();
  constante = new Constante();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  ogtService = new OgtService(this.constante.url_base_ogt);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaCartao: new Pessoa(),
    buscarCartaoCarregando: false,
    inputError: {
      cpf: false,
      numeroCartao: false,
    },
    tela: 1,
  }

  componentDidMount() {
    this.iniciar()
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
    let pessoaCartao = new Pessoa();

    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaCartao: pessoaCartao
    })
  }

  async buscarCartao() {
    this.setState({ buscarCartaoCarregando: true });
    let pessoaCartao = this.state.pessoaCartao;
    let inputError = this.state.inputError;
    let inputOk = true;
    Object.keys(inputError).forEach(key => {
      inputError[key] = false
    });

    if (!pessoaCartao.documento) {
      inputError.cpf = true
    }

    if (pessoaCartao.documento && pessoaCartao.documento.length !== 14) {
      inputError.cpf = true
    }

    if (!pessoaCartao.tags1) {
      inputError.numeroCartao = true
    }

    Object.keys(inputError).forEach(key => {
      if (inputError[key]) {
        inputOk = false
      }
    })

    if (!inputOk) {
      this.setState({ inputError: inputError, buscarCartaoCarregando: false });
      return
    }

    const listaPesquisa = [];
    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.documento';
    pesquisa.conteudo = pessoaCartao.documento;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.tags1';
    pesquisa.conteudo = pessoaCartao.tags1;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPerfilFuncionarioOK;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    console.log(listaPesquisa);
    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    pessoaCartao = retorno.objeto[0];

    this.setState({ buscarCartaoCarregando: false, pessoaCartao: pessoaCartao, tela: 2 });
  }


  async buscarSaldo() {
    this.setState({ buscarSaldoCarregando: true })
    let pessoaCartao = this.state.pessoaCartao;

    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN");

    let listaItem = [];
    let pedidoItemOgt = new PedidoItemOgt();
    pedidoItemOgt.nomeFuncionario = pessoaCartao.nome;
    pedidoItemOgt.cpf = pessoaCartao.documento;
    pedidoItemOgt.numeroCartao = pessoaCartao.tags1;
    pedidoItemOgt.idOperadora = pessoaCartao.tagn3;
    pedidoItemOgt.loginOperadora = pessoaCartao.tags5;
    pedidoItemOgt.senhaOperadora = pessoaCartao.tags6;
    pedidoItemOgt.cdempresa = this.constante.cdEmpresa;
    console.log('Pedido Item', pedidoItemOgt);
    listaItem.push(pedidoItemOgt);

    let retorno = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaSuperAdmin);
    let pessoaSuperAdmin = retorno.objeto;
    let pedidoOgt = new PedidoOgt();
    pedidoOgt.idArquivo = this.constante.idArquivo;
    pedidoOgt.pedidoItens = listaItem;
    pedidoOgt.idPedido = pessoaSuperAdmin.tagn0 + 1;
    pedidoOgt.nrSolicitacao = 0;
    console.log('Pedido', pedidoOgt);

    pessoaSuperAdmin.tagn0 = pedidoOgt.idPedido;
    retorno = await this.pessoaService.salvar(pessoaSuperAdmin);
    retornoOgt = await this.ogtService.enviarLote(pedidoOgt, token);
    console.log("lote", retornoOgt);

    if (retornoOgt?.success === false) {
      pessoaCartao.tags9 = '1';
      this.toast.current.show([
        { severity: 'error', summary: retornoOgt.errors[0] },
      ]);
      this.setState({ buscarSaldoCarregando: false })
      return
    } else {
      pessoaCartao.tags9 = '2';
      pessoaCartao.tagn4 = pedidoOgt.idPedido;
      retorno = await this.pessoaService.salvar(pessoaCartao);
      this.toast.current.show([
        { severity: 'success', summary: ' Saldo buscado com sucesso!' },
      ]);

    }
    let date = new Date();
    let pessoaAtendimento = new PessoaAtendimento();
    pessoaAtendimento.seqPessoa = this.state.pessoaUsuario._seqPessoa;
    pessoaAtendimento.seqTipoAtendimento = this.constante.seqTipoAtendimentoRequisicaoOGT;
    pessoaAtendimento.descricao = `${listaItem?.length} \n ${date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`;
    let retornoAtendimento = await this.pessoaService.salvarAtendimento(pessoaAtendimento);
    console.log('ATENDIMENTO', retornoAtendimento);
    console.log('SALVO', retorno.objeto);
    this.setState({ buscarSaldoCarregando: false, pessoaCartao: pessoaCartao });
  }

  async AtualizarSaldo() {
    this.setState({ buscarCarregando: true });
    let pessoaCartao = this.state.pessoaCartao;
    console.log("Cartão selecionado", pessoaCartao)
    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN", token);
    retornoOgt = await this.ogtService.obterResultado(pessoaCartao.tagn4, 0, token);
    console.log('retorno ogt', retornoOgt);

    if (!retornoOgt.data) {
      this.toast.current.show([
        { severity: 'warn', summary: "Informativo", detail: retornoOgt.statusPedido },
      ]);
      this.setState({ buscarCarregando: false })
      return

    } else {
      pessoaCartao.tagn2 = retornoOgt.data[0].saldo;
      pessoaCartao.tagn5 = retornoOgt.data[0].saldoDesmembrado;
      pessoaCartao.tagn6 = retornoOgt.data[0].saldoPendenteDesmembrado;
      pessoaCartao.tags7 = this.util.formatarHora(retornoOgt.data[0].dataUltimaAtualizacao.split("T")[1]);
      pessoaCartao.tags8 = this.util.formatarHora(retornoOgt.data[0].dataProcessamento.split("T")[1]);
      pessoaCartao.tagd0 = this.util.converterDataRegex(retornoOgt.data[0].dataUltimaAtualizacao);
      pessoaCartao.tagd1 = this.util.converterDataRegex(retornoOgt.data[0].dataProcessamento);
      pessoaCartao.tags9 = '1';
      let retornoPessoa = await this.pessoaService.salvar(pessoaCartao);
      pessoaCartao = retornoPessoa.objeto;
      this.toast.current.show([
        { severity: 'success', summary: ' Saldo atualizado com sucesso!' },
      ]);
      this.setState({ buscarCarregando: false, pessoaCartao: pessoaCartao });
    }
  }

  render() {
    return <div>
      <HeaderEmpresa />
      <main style={{ width: '95%', margin: '20px auto' }}>
        <Toast ref={this.toast} />
        <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
          <div style={{ display: "flex", alignItems: "center", gap: 15, }}>
            {this.state.tela === 2 && <Button label={<i className="pi pi-arrow-left" />} onClick={() => this.setState({ tela: 1 })} />}
            <h2>Consulta avulsa</h2>
          </div>
        </div>

        {this.state.tela === 1 && <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
          <div style={{ display: "flex", alignItems: "center", gap: 5, }}>
            <i style={{ color: this.constante.corAzul, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corAzul}` }} className="pi pi-id-card" />
            <h2>Dados do cartão</h2>
          </div>

          <form
            onSubmit={e => {
              e.preventDefault();
              this.buscarCartao();
            }}
            style={{ display: 'grid', marginTop: 20, }}>
            <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', gap: 30 }}>
              <div>
                <label>CPF do funcionário</label><br />
                <InputText
                  value={this.state.pessoaCartao.documento || ''}
                  onChange={pTexto => this.setState({
                    pessoaCartao: {
                      ...this.state.pessoaCartao, documento: this.util.formatarCPF(pTexto.target.value)
                    }
                  })}
                  style={{
                    width: "100%",
                    borderColor: this.state.inputError.cpf ? "red" : 'black',
                  }}
                />
                {this.state.inputError.cpf && <small style={{ color: "red" }}>CPF inválido</small>}
              </div>

              <div>
                <label>Número cartão</label><br />
                <InputText
                  value={this.state.pessoaCartao.tags1 || ''}
                  onChange={pTexto => this.setState({
                    pessoaCartao: {
                      ...this.state.pessoaCartao, tags1: pTexto.target.value
                    }
                  })}
                  style={{
                    width: "100%",
                    borderColor: this.state.inputError.numeroCartao ? "red" : 'black',
                  }}
                />
                {this.state.inputError.numeroCartao && <small style={{ color: "red" }}>Número cartão inválido</small>}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                loading={this.state.buscarCartaoCarregando}
                label="Buscar cartão" />
            </div>
          </form>
        </div>}

        {this.state.tela === 2 && <><div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
          <div style={{ display: "flex", alignItems: "center", gap: 5, }}>

            <i style={{ color: this.constante.corAzul, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corAzul}` }} className="pi pi-id-card" />
            <h2>Dados do cartão</h2>
          </div>

          <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', gap: 30, marginTop: 20 }}>

            <div>
              <label>CPF do funcionário</label><br />
              <InputText
                disabled
                value={this.state.pessoaCartao.documento || ''}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>

            <div>
              <label>Número cartão</label><br />
              <InputText
                disabled
                value={this.state.pessoaCartao.tags1 || ''}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>


            <div>
              <label>Nome</label><br />
              <InputText
                disabled
                value={this.state.pessoaCartao.nome || ''}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>


            <div>
              <label>Matricula</label><br />
              <InputText
                disabled
                value={this.state.pessoaCartao.tags0 || ''}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>

            <div>
              <label>Operadora</label><br />
              <InputText
                disabled
                value={this.state.pessoaCartao.tags2 || ''}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>

            <div>
              <label>Dias trabalhados</label><br />
              <InputText
                disabled
                value={this.state.pessoaCartao.tagn0 || ''}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>

            <div>
              <label>Dias da semana</label><br />
              <InputText
                disabled
                value={this.state.pessoaCartao.tagn7 === 1 ? 'Segunda a sexta' : this.state.pessoaCartao.tagn7 === 2 ? "Segunda a sábado" : this.state.pessoaCartao.tagn7 === 3 ? 'Segunda a domingo' : 'não definido'}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>


            <div>
              <label>Valor diário</label><br />
              <InputNumber
                disabled
                mode="currency"
                currency="BRL"
                locale="pt-BR"
                value={this.state.pessoaCartao.tagn1 || ''}
                style={{ width: '100%', height: 40 }}
              /><br />
            </div>


            <div>
              <label>Empresa</label><br />
              <InputText
                disabled
                value={this.state.pessoaUsuario.nome || ''}
                style={{
                  width: "100%",
                  borderColor: 'lightgrey',
                }}
              />
            </div>
          </div>
        </div>


          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
            <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 1 }}>
              <i className="pi pi-bookmark" style={{ color: this.constante.corAzul, fontSize: '1rem', padding: 8, borderRadius: 360, border: `1px solid ${this.constante.corAzul}` }} />
              <h3>Saldo</h3>
            </div>

            <div style={{ width: '100%', display: "flex", gap: 20, marginTop: 20 }}>

              <div>
                <label>Saldo</label><br />
                <InputNumber
                  disabled
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  value={this.state.pessoaCartao.tagn2 || ''}
                  style={{ width: '100%', height: 40 }} />
              </div>

              <div>
                <label>Status</label>
                {this.state.pessoaCartao.tags9 === "2" ?
                  <p style={{ color: "orange", marginTop: 12 }}>Aguardando</p> :
                  <p style={{ color: "green", marginTop: 12 }}>Atualizado</p>
                }
              </div>

              <div>
                <Button
                  style={{ marginTop: 15 }}
                  label="Buscar saldo"
                  disabled={this.state.pessoaCartao.tags9 === "2"}
                  loading={this.state.buscarSaldoCarregando}
                  className="p-button-raised"
                  onClick={() => this.buscarSaldo()}
                />
              </div>


              <div>
                <Button
                  style={{ marginTop: 15 }}
                  disabled={this.state.pessoaCartao.tags9 === "1"}
                  label="Atualizar saldo"
                  loading={this.state.buscarSaldoCarregando}
                  className="p-button-raised"
                  onClick={() => this.AtualizarSaldo()}
                />
              </div>
            </div>

            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
              <div>
                <label>Data ultima consulta</label><br />
                <Calendar
                  disabled
                  value={this.util.formatarDataGerandoNovaData(this.state.pessoaCartao.tagd1) || ''}
                  showButtonBar
                  style={{ width: "100%", height: 40 }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                />
              </div>

              <div>
                <label>Hora ultima consulta</label><br />
                <InputText
                  disabled
                  value={this.util.formatarHora(this.state.pessoaCartao.tags8) || ''}
                  style={{ width: '100%', height: 40, }} />
              </div>
            </div>
          </div>
        </>
        }
      </main >
    </div >
  }
}