import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import HeaderEmpresa from "./Components/HeaderEmpresa";
import Constante from "../../Util/Constante";


export default class EmpresaDocumento extends Component {
  constante = new Constante();
  state = {
    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {

  }

  async listar() {

  }
  render() {
    return (
      <div>
        <HeaderEmpresa />
        <main style={{ width: "95%", margin: "20px auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid lightgray",
              padding: 20,
              borderRadius: 3,
            }}
          >
            <h1 style={{ fontSize: 22 }}>Pedidos</h1>

            <Button
              label="Novo"
              className="p-button-raised"
              onClick={() => this.setState({ vModalNovo: true })} />
          </div>
          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr ', gap: 20 }}>
              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pTexto.target.value
                    }
                  })}
                  placeholder="Nome"
                  value={this.state.filtro.nome}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>Data Cadastro Inicial</label><br />
                <Calendar
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  onChange={(pTexto) => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pTexto.target.value
                    }
                  })}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>Data Cadastro Final</label><br />
                <Calendar
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  onChange={(pTexto) => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pTexto.target.value
                    }
                  })}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gridTemplateColumns: "1fr",
              marginTop: 20,
              gap: 10,
            }}
          >
            <DataTable
              selectionMode="single"
              showGridlines
              removableSort
              paginator
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              style={{ width: "100%" }}
              value={this.state.listaCartao}
              onSelectionChange={pEvento => this.selecionarCondominio(pEvento.value)}
            >
              <Column header='Nº' field='nome' sortable />
              <Column header='Status' field='tags0' sortable />
              <Column header='Data' field='tags0' sortable />
            </DataTable>
          </div>
        </main>
      </div>
    );
  }
}
