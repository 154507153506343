import { Button } from "primereact/button";
import React, { Component } from "react";
import HeaderAdm from "./../Administrativo/Components/HeaderAdm";

import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import DetalheEmpresa from "./DetalheEmpresa";
import { Link } from "react-router-dom";

export default class Empresa extends Component {

  constante = new Constante();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base)

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEmpresa: new Pessoa(),
    salvarCarregando: false,
    tabelaCarregando: false,
    tela: 1,

    listaEmpresa: [],

    //FILTROS
    filtroPesquisa: {
      nome: '',
      documento: '',
      dataCadastro_inicial: '',
      dataCadastro_final: '',
    },
    bloquearHeader: true,
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    this.listar()

    this.setState({
      pessoaUsuario: pessoaUsuario,
    })
  }


  async listar() {
    this.setState({ tabelaCarregando: true });
    this.listaPesquisa = [

      this.util.criarObjetoPesquisa(
        "pessoa.seq_usuario",
        this.constante.seqUsuario,
        "",
        "TEXTO"
      ),
      this.util.criarObjetoPesquisa(
        "pessoa.seq_pessoa_perfil",
        this.constante.seqPerfilEmpresa,
        "AND",
        "TEXTO"
      ),

    ]

    if (this.state.filtroPesquisa.nome) {
      this.listaPesquisa.push(
        this.util.criarObjetoPesquisa(
          'pessoa.nome',
          this.state.filtroPesquisa.nome,
          'AND',
          'TEXTO'
        )
      )
    }

    if (this.state.filtroPesquisa.documento) {
      this.listaPesquisa.push(
        this.util.criarObjetoPesquisa(
          'pessoa.documento',
          this.state.filtroPesquisa.documento,
          'AND',
          'TEXTO'
        )
      )
    }

    if (this.state.filtroPesquisa.dataCadastro_inicial) {
      this.listaPesquisa.push(
        this.util.criarObjetoPesquisa(
          'pessoa.data_cadastro',
          this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_inicial),
          'AND',
          'DATA_INICIAL'
        )
      )
    }

    if (this.state.filtroPesquisa.dataCadastro_final) {
      this.listaPesquisa.push(
        this.util.criarObjetoPesquisa(
          'pessoa.data_cadastro',
          this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_final),
          'AND',
          'DATA_FINAL'
        )
      )
    }


    console.log("lista pesquisa", this.listaPesquisa);

    this.retornoListaPequisa = await this.pessoaService.listarComFiltro(this.listaPesquisa)
    this.listaEmpresa = this.retornoListaPequisa.objeto
    console.log("lista de empresa", this.retornoListaPequisa.objeto);

    // this.listaEmpresa.forEach(element => {
    //   element.seqPessoaPerfil = this.constante.seqPessoaPerfilDeletar;
    //   this.pessoaService.salvar(element);
    // });
    this.setState({
      listaEmpresa: this.listaEmpresa,
      salvarCarregando: false,
      tabelaCarregando: false,
    })


  }

  render() {
    return (
      <div>
        <HeaderAdm
          pessoaEmpresa={this.state.pessoaEmpresa}
          telaBloqueada={this.state.bloquearHeader} />

        {this.state.tela === 2 ?
          <DetalheEmpresa
            listar={() => this.listar()}
            setState={pState => this.setState(pState)}
            pessoaEmpresa={this.state.pessoaEmpresa} />
          :

          <main style={{ width: '95%', margin: '20px auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                <div>
                  <h1 style={{ fontSize: 22 }}>Empresas</h1>
                  <h2 style={{ fontSize: 20, color: 'gray' }}>Empresas</h2>
                </div>
              </div>
            </div>


            {/* FILTROS */}

            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr ', gap: 20 }}>
                <div>
                  <label>Empresa</label><br />
                  <InputText
                    onChange={pTexto => this.setState({ filtroPesquisa: { ...this.state.filtroPesquisa, nome: pTexto.target.value } })}
                    value={this.state.filtroPesquisa.nome || ''}
                    placeholder="Nome"
                    style={{ width: '100%', height: 40 }}
                  />
                </div>

                <div>
                  <label>Data Cadastro Inicial</label><br />
                  <Calendar
                    onChange={pTexto => this.setState({
                      filtroPesquisa: {
                        ...this.state.filtroPesquisa, dataCadastro_inicial: pTexto.target.value
                      }
                    })}
                    value={this.state.filtroPesquisa.dataCadastro_inicial || ''}
                    showButtonBar
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    style={{ width: '100%', height: 40 }}
                  />
                </div>

                <div>
                  <label>Data Cadastro Final</label><br />
                  <Calendar
                    onChange={pTexto => this.setState({
                      filtroPesquisa: {
                        ...this.state.filtroPesquisa, dataCadastro_final: pTexto.target.value
                      }
                    })}
                    value={this.state.filtroPesquisa.dataCadastro_final || ''}
                    showButtonBar
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    style={{ width: '100%', height: 40 }}
                  />
                </div>


              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
              </div>
            </div>

            {/* TABELA */}

            <div style={{ display: 'flex', gridTemplateColumns: '1fr', marginTop: 20, gap: 10, }}>

              <DataTable
                selectionMode="single"
                showGridlines removableSort
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                style={{ width: "100%" }}
                value={this.listaEmpresa}
                loading={this.state.tabelaCarregando}
                onSelectionChange={pEvento => {
                  this.setState({ pessoa: pEvento.value, tela: 2, bloquearHeader: true, pessoaEmpresa: pEvento.value })
                }}>

                <Column
                  header="Empresa"
                  field="nome"
                  sortable
                  body={(pItem) => <p style={{ color: this.constante.azul, textDecoration: 'underline' }}>{pItem.nome}</p>}
                />

                <Column
                  header="Email"
                  field="emailPlataformaLirida"
                  sortable />


                <Column
                  header="Data de cadastro"
                  field="dataCadastro"
                  sortable
                  body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />

              </DataTable>
            </div>

          </main>
        }
      </div>
    );
  }
}
