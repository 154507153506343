import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";

export default class Ogt extends Component {

    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaFiltro: new Pessoa(),
        listaPessoa: [],
        listaRequisicao: [],
        buscarCarregando: false,
        tabelaCarregando: false,
        inputError: false,
        filtros: {
            dataCadastro_inicial: '',
            dataCadastro_final: '',
        },
    }

    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        // this.listar(pessoaUsuario)
        let retorno = await this.listarPessoa();
        this.setState({ pessoaUsuario: pessoaUsuario, listaPessoa: retorno });
    }

    async listar(pPessoa) {
        if (!pPessoa._seqPessoa) {
            this.setState({ inputError: true })
            return
        }
        this.setState({ buscarCarregando: true, tabelaCarregando: true, inputError: false })
        let pessoaFiltro = this.state.pessoaFiltro;
        if (pPessoa) {
            pessoaFiltro = pPessoa;
        }

        console.log('pessoa filtro', pessoaFiltro);
        let retorno = await this.pessoaService.listarAtendimento(pessoaFiltro);
        let listaRequisicao = retorno.objeto;
        this.setState({
            listaRequisicao: listaRequisicao
        })
        this.setState({ buscarCarregando: false, tabelaCarregando: false, })
        return listaRequisicao
    }

    async listarPessoa() {
        const listaPesquisa = [];
        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_pessoa_perfil";
        pesquisa.conteudo = this.constante.seqPerfilAdministrador;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        const retorno = await this.pessoaService.listarComFiltro(listaPesquisa)
        let listaPessoa = retorno.objeto
        console.log("perfil", listaPessoa);
        this.setState({ listaPessoa: listaPessoa })
        return listaPessoa
    }

    // deletarAtendimento(item) {
    //     let atendimento = item;
    //     atendimento.seqPessoa = 'fc473a53-b5d4-4954-8820-0c5a0387d11d';
    //     this.pessoaService.salvarAtendimento(atendimento);
    // }

    render() {
        return (
            <div>
                <HeaderAdm
                    telaBloqueada={true} />
                <main style={{ width: "98%", margin: "20px auto" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border: "1px solid lightgray",
                            padding: 20,
                            borderRadius: 3,
                        }}
                    >
                        <div>
                            <h1 style={{ fontSize: 22 }}>Controle de requisições</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Controle de requisições</h2>
                        </div>
                    </div>

                    {/* FILTROS */}
                    <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
                            <div>
                                <label>Perfil</label><br />
                                <Dropdown
                                    onChange={(pItem) => this.setState({
                                        pessoaFiltro: pItem.value
                                    })}
                                    value={this.state.pessoaFiltro}
                                    optionLabel="nome"
                                    placeholder="Selecione"
                                    options={this.state.listaPessoa}
                                    style={{ width: '90%', borderColor: this.state.inputError && "var(--red-600)", }}
                                />{this.state.inputError && <small style={{ color: "var(--red-600)" }}>Selecione</small>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: "100%", marginTop: 20, justifyContent: "flex-end" }}>
                            <Button loading={this.state.buscarCarregando} label="Buscar" className="p-button-raised"
                                onClick={() => this.listar(this.state.pessoaFiltro)} />
                        </div>
                    </div>


                    <div style={{ marginTop: 20 }}>
                        <DataTable
                            showGridlines
                            removableSort
                            paginator
                            responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={5}
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            style={{ width: "100%" }}
                            value={this.state.listaRequisicao}
                            header={<h2>Quantidade de requisições: {this?.state?.listaRequisicao?.length}</h2>}>

                            <Column header='Data requisição'
                                field='dataCadastro'
                                sortable
                                body={pItem => <p>{this.util.formatarDataComTimestamp(pItem.dataCadastro)}</p>}
                            />

                            <Column header='Hora da Requisição'
                                field='descricao'
                                sortable
                                body={pItem => <p>{pItem.descricao.split('\n')[1]}</p>} />

                            <Column header='Quantidade cartões'
                                field='descricao'
                                sortable
                                body={pItem => <p>{pItem.descricao.split('\n')[0]}</p>} />

                            {/* <Column header='Deletar' sortable body={(item) => <Button
                                icon="pi pi-trash"
                                onClick={() => this.deletarAtendimento(item)} />} /> */}

                        </DataTable>
                    </div>
                </main>
            </div>
        );
    }
}