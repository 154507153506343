import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util_api from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';

export default class Util {
  constante = new Constante();
  util_api = new Util_api(this.constante.token, this.constante.url_base);
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  enviarEmail(pApiEmail) {
    this.util_api.enviarEmail(pApiEmail);
  }

  enviarWhatsApp(pApiWhatsApp) {
    this.util_api.enviarWhatsApp(pApiWhatsApp);
  }

  async buscarUrlBaseArquivo() {
    let retorno = await this.util_api.buscarUrlBaseArquivo();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarUrlBaseFinanceiro() {
    let retorno = await this.util_api.buscarUrlBaseFinanceiro();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarLocalStorage(pCampo) {
    let retorno = localStorage.getItem(pCampo);
    retorno = await JSON.parse(retorno);
    return retorno;
  }

  salvarLocalStorage(pCampo, pValor) {
    let valor = JSON.stringify(pValor);
    localStorage.setItem(pCampo, valor);
    console.log(pCampo + ' salvo localstorage com sucesso')
  }

  removerLocalStorage(pCampo) {
    let retorno = localStorage.removeItem(pCampo);
    return retorno;
  }

  formatarTelefone(value) {
    let r = value.replace(/\D/g, '');
    r = r.replace(/^0/, '');

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (value.trim() !== '') {
      r = r.replace(/^(\d*)/, '($1');
    }

    return r;
  }

  formatarData(pData) {
    let data = pData;
    data = data.split("/").join("-");
    return data + "T00:00:00";
  }

  formatarDataBanco(pData) {
    let data = pData.toLocaleDateString('pt-BR');
    data = `${data.split('/').join('-')}T00:00:00`;
    console.log("DATA PARA O BANCO")
    console.log(data)
    return data
  }
  formatarDataComTimestamp(pData) {
    if (!pData) return;

    let data = pData;
    data = data.split("T")[0].split("-").join("/");
    return data;
  }

  formatarDataInput(pData) {
    if (String(pData).includes('/')) {
      this.data = String(pData).split('/').reverse().join('-');
    } else if (String(pData).includes('-')) {
      this.data = String(pData).split('-').reverse().join('/');
    } else {
      this.data = pData;
    }

    return this.data;
  }

  formatarDataCalendar(pData) {
    let data = String(pData)
    data = `${data.split('T')[0].split('-').reverse().join('-')}T03:00:00.000Z`;
    return new Date(data)
  }

  formatarCPFCNPJ(pTexto) {
    let t = pTexto;

    if (t.length > 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    }
  }

  formatarCPF(pTexto) {
    let t = pTexto;
    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }
  }


  formatarCNPJ(pTexto) {
    return pTexto.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  formatarValor(value) {
    if (value) {
      this.valorFormatado = Number(value).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    } else {
      this.valorFormatado = Number(0).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    }
    return this.valorFormatado;
  };

  formatarCEP(pTexto) {
    this.texto = pTexto;
    return this.texto.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }

  async validarDocumento(pDocumento) {
    this.retorno = await this.util_api.validarDocumento(pDocumento);
    this.retorno = await this.retorno.json();
    return this.retorno;
  }

  retornaPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa();

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;
  }

  async listarLista(pSeqUsuario, pID) {
    this.retorno = await this.util_api.listarLista(pSeqUsuario, pID);
    this.retorno = this.retorno.json();
    return this.retorno;
  }

  async listarBanco(pConteudo) {
    this.retorno = await this.util_api.listarBanco(pConteudo);
    this.retorno = this.retorno.json();
    return this.retorno;
  }

  buscarParametros() {
    this.listaParametrosSplitados = window.location.search.replace('?', '').split('&');
    this.parametros = {};

    for (let i = 0; i < this.listaParametrosSplitados.length; i++) {
      this.listaChaveValor = this.listaParametrosSplitados[i].split('=');
      this.parametros[this.listaChaveValor[0]] = this.listaChaveValor[1];
    }

    return this.parametros;
  }

  async buscarUsuarioPorSeq(pSeqUsuario) {
    this.usuario = await this.util_api.buscarUsuarioPorSeq(pSeqUsuario);
    return this.usuario;
  }

  async pegarBase64(pArquivo) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(pArquivo);
      reader.onload = () => {
        let base64 = reader.result;
        base64 = base64.split(",")[1];
        resolve(base64);
      };
      reader.onerror = error => reject(error);
    })
  }

  validarEmail(pEmail) {
    let emailRegex = /\S+@\S+\.\S+/;

    return emailRegex.test(pEmail);
  }

  validarTelefone(pTelefone) {
    let telefoneRegex = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;

    return telefoneRegex.test(pTelefone);
  }
  criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa()

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;

  }

  converterDataRegex(date) {
    var regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    var newFormat = "$3-$2-$1T$4:$5:$6";
    return date.replace(regex, newFormat);
  }

  inconsistencia(pPessoaFuncionario) {
    let pessoa = pPessoaFuncionario;
    let incosistencia = false;
    if (!pessoa?.nome) {
      incosistencia = true;
    }

    if (!pessoa?.tags0) {
      incosistencia = true;
    }

    if (!pessoa?.tags1) {
      incosistencia = true;
    }

    if (!pessoa?.tags3) {
      incosistencia = true;
    }

    // if (!pessoa?.tags4) {
    //   incosistencia = true;
    // }

    // if (!pessoa?.tags5) {
    //   incosistencia = true;
    // }
    if (!pessoa?.tagn0) {
      incosistencia = true;
    }

    if (!pessoa?.tagn1) {
      incosistencia = true;
    }
    return incosistencia;
  }

  removerZeroECaracter(pString) {
    let texto = pString;
    texto = texto.replace(/^0+/, '');
    texto = texto.replace(/[^\d]/g, '');
    return texto
  }

  formatarHora(pHora) {
    if (!pHora) {
      return
    }
    let colonIndex = pHora.indexOf(':');
    return pHora.substring(0, colonIndex + 3);
  }

  formatarDataGerandoNovaData(pData) {
    if (!pData) {
      return
    }
    let dataUltimaAtualizacao = pData.split("T")[0];
    let data = new Date();
    data.setDate(dataUltimaAtualizacao.split("-")[0]);
    data.setMonth(Number(dataUltimaAtualizacao.split("-")[1]) - 1);
    data.setFullYear(dataUltimaAtualizacao.split("-")[2]);
    return data
  }

  removeZerosEsquerda(str) {
    return str.replace(/^0+/, '');
  }

  formatarNumero(input) {
    var regex = /^\d+(\.\d+)?$/;
    if (input.match(regex)) {
      return parseInt(input);
    }
    return '';
  }

  limpaFormatacao(campo) {
    // remove todos os caracteres não numéricos
    campo = campo.replace(/\D/g, '');
    return campo;
  }

  formatarValorParaInteiro(pValor) {
    let valor = String(pValor);
    if (valor.indexOf('.') !== -1) {
      valor = parseFloat(valor.replace(",", ".")).toFixed(2).replace(".", "");
    } else {
      valor = parseInt(valor) * 100;
    }
    return valor;
  }

  formatarSemCaracter(pValor) {
    return pValor.replace(/[^a-zA-Z0-9]/g, "");
  }

  // gerarHeaderPedido(pDocumento) {
  //   let numeroSequencial = 1;
  //   numeroSequencial = numeroSequencial.toString().padStart(5, '0');
  //   let tipoRegistro = '01';
  //   const nomeArquivo = 'PEDIDO';
  //   const numeroVersaoLayout = '01.00';

  //   let numeroDocumentoComprador = this.limpaFormatacao(pDocumento).toString().padStart(14, '0');

  //   let header = `${numeroSequencial}${tipoRegistro}${nomeArquivo}${numeroVersaoLayout}${numeroDocumentoComprador}\n`
  //   return header
  // }

  // gerarRegistroDetalhe(pNumeroSequencial, pMatricula, pValorCarga) {
  //   const numeroSequencial = pNumeroSequencial.toString().padStart(5, '0');
  //   const tipoRegistro = '02';
  //   const valorCarga = pValorCarga.toString().padStart(8, '0');
  //   const matricula = (pMatricula + ' '.repeat(15)).slice(0, 15);

  //   let registroDetalhe = `${numeroSequencial}${tipoRegistro}${matricula}${valorCarga}\n`;
  //   return registroDetalhe
  // }

  // gerarRegistroTrailer(pNumeroSequencial, pValorTotal) {
  //   let numeroSequencial = pNumeroSequencial.toString().padStart(5, '0');
  //   let tipoRegistro = '99';
  //   let valorPedido = this.formatarValorParaInteiro(pValorTotal).toString().padStart(10, '0');
  //   let registroTrailer = `${numeroSequencial}${tipoRegistro}${valorPedido}`
  //   return registroTrailer
  // }

  async gerarArquivoTxtRioCardMais(pDocumentoComprador, pListaCartao) {
    let numeroSequencial = 1;
    let tipoRegistroHeader = '01';
    const nomeArquivo = 'PEDIDO';
    const numeroVersaoLayout = '01.00';
    let documentoComprador = await this.limpaFormatacao(pDocumentoComprador).toString().padStart(14, '0');
    let header = `${numeroSequencial.toString().padStart(5, '0')}${tipoRegistroHeader}${nomeArquivo}${numeroVersaoLayout}${documentoComprador}\n`;

    const listaCartao = [];
    let listaCartaoValidarValor = pListaCartao;
    for (const cartao of listaCartaoValidarValor) {
      if (cartao.tagn9) {
        listaCartao.push(cartao);
      }
    }


    numeroSequencial = numeroSequencial + 1;
    let tipoRegistroDetalhe = '02';
    let registroDetalhe = '';
    let valorTotal = 0;


    for (const cartao of listaCartao) {
      console.log(cartao.tagn9)
      let valorCarga = await this.formatarValorParaInteiro(cartao.tagn9).toString().padStart(8, '0');
      valorTotal = valorTotal + cartao.tagn9;
      let matricula = ((this.formatarSemCaracter(cartao.tags0) + ' '.repeat(15)).slice(0, 15));
      registroDetalhe += `${numeroSequencial.toString().padStart(5, '0')}${tipoRegistroDetalhe}${matricula}${valorCarga}\n`
      numeroSequencial++
    }

    numeroSequencial = numeroSequencial.toString().padStart(5, '0');
    let tipoRegistroFinal = '99';
    let valorPedido = await this.formatarValorParaInteiro(valorTotal).toString().padStart(10, '0');
    let registroTrailer = `${numeroSequencial}${tipoRegistroFinal}${valorPedido}`;


    let data = new Date();
    const ano = data.getFullYear().toString();
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const dia = data.getDate().toString().padStart(2, '0');
    const dataFormatada = `${ano}${mes}${dia}`;
    const hora = data.getHours().toString().padStart(2, '0');
    const minuto = data.getMinutes().toString().padStart(2, '0');
    const horaAtual = `${hora}${minuto}`;


    const nomeExternoArquivo = `PEDIDO_0100_${documentoComprador}_${dataFormatada}_${horaAtual}`
    const registroTxt = `${header}${registroDetalhe}${registroTrailer}`;

    const elemento = document.createElement("a");
    const arquivo = new Blob([registroTxt], { type: 'text/plain' });
    elemento.href = URL.createObjectURL(arquivo);
    elemento.download = nomeExternoArquivo;
    document.body.appendChild(elemento);
    elemento.click();
    return arquivo
  }
}